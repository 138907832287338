<template>
  
    <pined-video :path="video.src" :switchDuration="1" name="get-the-annual-review">
      <div style="height: 25vh;"></div>
      <section-cover text="Get the Annual Review" id="get-review-page-start" name="get-the-annual-review"   route="get-the-annual-reviews">

        <div>
          <div ref="triggerStart" class="spacer"></div>
        </div>

        <div class="space-for-video">
          <div ref="triggerStart" class="spacer"></div>
        </div>

        <div class="space-for-video half"></div>

        <div class="get-the-annual-review-wrapper" id="get-the-annual-review-content">
          <OffscreenTransitionContainer>
            <div class="page-text ">
              <div slot="background">
                <BackgroundContainer backgroundColorClass="dark-bg"></BackgroundContainer>
              </div>
              <div slot="tables">
                <div class="container container-review-padding">

                  <!-- Downloads row  -->

                  <div class="row">
                    <div class="col-12 col-md-6 offset-md-5 col-xl-3 offset-xl-4 review-row-title">Downloads</div>
                    <div class="col-12 col-md-6 offset-md-5 col-xl-5 offset-xl-0">
                      <div class="row review-head-text">
                        <div class="col-12 small-body noindent">
                          Pictet’s 2019 Annual Review publication includes additional information, our financial figures, consolidated income statement and balance sheet and can be downloaded below. Should you want to order a printed version, please follow the link. 
                        </div>
                      </div>
                      <div class="row ">
                        <div class="col-12">
                          <div class="review-link">
                            <a href=" https://www.group.pictet/about/annual-review#order" target="_blank">Order printed publication</a> 
                          </div>
                        </div>
                      </div>
                      <div class="row downloads-table">
                        <div class="col-12">

                          <a href="https://static.group.pictet/sites/default/files/2020-05/Pictet-AR2019-ENG-Desktop.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">Annual Review 2019	 </div>
                              <div class="downloads-row-file">English, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>
                          
                          <a href="https://static.group.pictet/sites/default/files/2020-05/Pictet-AR2019-FRA-Desktop.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">Rétrospective annuelle 2019 </div>
                              <div class="downloads-row-file">Français, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>

                          <a href="https://static.group.pictet/sites/default/files/2020-05/Pictet-AR2019-GER-Desktop.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">Jahresbericht 2019 </div>
                              <div class="downloads-row-file">Deutsch, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>

                          <a href="https://asia.group.pictet/sites/asia/files/2020-05/Pictet_AR2019_SC_Desktop.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">2019 <span class="chainise-signs">年度报告</span></div>
                              <div class="downloads-row-file"><span class="chainise-signs">简体中文</span>, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>

                          <a href="https://asia.group.pictet/sites/asia/files/2020-05/Pictet_AR2019_TC_Desktop.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">2019 <span class="chainise-signs">年度報告</span>	 </div>
                              <div class="downloads-row-file"><span class="chainise-signs">繁體中文</span>, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>
                          
                          <a href="https://static.group.pictet/sites/default/files/2020-05/Pictet-AR2019-Financial-annual-report-EN.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">Annual Report 2019 (full financial report)	 </div>
                              <div class="downloads-row-file">English, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>

                          <a href="https://static.group.pictet/sites/default/files/2020-04/AR2019-Financial-annual-report-FR.pdf" target="_blank">
                            <div class="downloads-row">
                              <div class="downloads-row-title">Rapport annuel (rapport financier complet) </div>
                              <div class="downloads-row-file">Français, PDF<div class="downloads-row-arrow">→</div></div>
                            </div>
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>



                  <!-- Find out more -->




                  <div class="row review-row-space">
                    <div class="col-12 col-md-6 offset-md-5 col-xl-3 offset-xl-4 review-row-title">Find out more</div>
                    <div class="col-12 col-md-6 offset-md-5 col-xl-5 offset-xl-0">
                      <div class="row review-head-text">
                        <div class="col-12 small-body noindent">
                          With CHF 576 billion in assets under management or custody, Pictet is a leading European-based independent wealth and asset manager headquartered in Switzerland. 
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 review-links-row">
                          <div class="review-link">
                            <a href="https://www.group.pictet/" target="_blank">Find out more about us at group.pictet website</a>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="margin-top:30px;">
                        <div class="col-12 review-links-row">
                          <div class="review-link social">
                            <a href="https://linkedin.com/company/the-pictet-group" target="_blank">LinkedIn</a>
                          </div>
                          <div class="review-link social">
                            <a href="https://twitter.com/pictetgroup" target="_blank">Twitter</a>
                          </div>
                          <div class="review-link social">
                            <a href="https://www.instagram.com/pictetgroup/" target="_blank">Instagram</a>
                          </div>
                          <div class="review-link social">
                            <a href="https://www.youtube.com/channel/UCdiUmEzyLLuPJPm9gA7PeiA" target="_blank">YouTube</a>
                            
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  
                  <div class="row review-row-space"></div>

                </div>
              </div>
            </div>

          </OffscreenTransitionContainer>
        </div>
        
      </section-cover>

      <div>
        <div ref="triggerEnd" class="spacer"></div>
      </div>

    </pined-video>

</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import SectionContainer from '@/components/section-container'
import PinedVideo from '@/components/pinned-video-container'
import SectionCover from '@/components/section-cover'
import PinedSection from '@/components/pined-section-container'
import BackgroundContainer from '@/components/section-background-container'
import OffscreenTransitionContainer from '@/components/section-offscreen-container'
import { ScrollScene } from 'scrollscene'

var video = {id: "video4", src: 'annual-review.mp4'}

export default {
  name: 'get-the-annual-review',
  mixins: [storeWatchMixin],
  components: {
    SectionContainer,
    PinedVideo,
    SectionCover,
    PinedSection,
    BackgroundContainer,
    OffscreenTransitionContainer
  },
  data(){
    return {
      video
    }
  },
  mounted (){
    
    // console.log("mounted -> get-the-annual-review")
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
