<template>

  <PinedVideo :path="video.src" name="business" >
    <SectionCover text="Our Business" id="business-page-start" name="business" route="business">



    <div class="space-for-video--mobile"></div>
    <div class="space-for-video half-500"></div>
      <!-- SECTION 1 - Wealth Management -->




      <PinedSection id="business-page-id-1" route="wealth-management">
        <OffscreenTransitionContainer :switchHamburgerIconToBlack="true">
          <SectionContainer class="business-pined-section" >
            
              <div slot="title">
                Wealth Management
              </div>
              <div slot="background">
                <BackgroundContainer></BackgroundContainer>
              </div>
              <div slot="column1">
                <div class="black-text small-body no-indent">
                  Pictet Wealth Management provides a comprehensive service for both wealthy individuals and families that goes beyond managing assets to consider wealth management in its widest sense. 
                </div>
                <div class="black-text small-body">  
                  We begin by understanding each client’s particular requirements regarding their family, future and extended ambitions, whether philanthropic or otherwise. Clients then decide how much they wish to be involved in the investment process, from delegating the management of their wealth to taking advantage of our investment expertise. <div class="black-text small-body no-block">This allows us to define an investment strategy that meets their financial goals in the context of their broader aspirations; finally, we add the operational platform best suited to achieving these goals.</div> 
                </div>
                <div class="black-text small-body">
                  For clients whose needs are more complex, we offer a full range of personalised solutions. These include dedicated holding structures, bespoke reporting, global custody and direct access to trading services. <div class="black-text small-body no-block">Our experts in family office services also help clients with multi-generation wealth transmission by designing the most appropriate governance for the family organisation, investment strategy and administration of portfolios.</div>
                </div>
              </div>
              
              <div slot="column2">
                <div class="black-text small-body">
                  Our investment capabilities extend across developed and emerging markets and cover almost all asset classes and currencies, including private assets and hedge funds. We also offer our international capabilities in holding client assets, as demanded by economic, geopolitical or personal circumstances. 
                </div>
                <div class="black-text small-body">
                  In short, our aim is to be the trusted advisor of reference for our clients.

                </div>
              </div>

              <div slot="tables">
                <div class="key-number-text--red">
                  <SectionKeyNumbers :data="keynumbersWealthManagement" :footnotes="keynumbersWealthManagementfootnotes" >
                    <div slot="header-title">
                      Key Figures
                    </div>
                  </SectionKeyNumbers>
                </div>
              </div>
            
          </SectionContainer>
          
          
        </OffscreenTransitionContainer>
      </PinedSection>





      <!-- SECTION 2 - Asset Management -->




      <PinedSection id="business-page-id-2" route="asset-management">
          <SectionContainer class="business-pined-section">
            
              <div slot="title">
                Asset Management
              </div>
              <div slot="background">
                <BackgroundContainer  backgroundColorClass="offwhite-bg"></BackgroundContainer>
              </div>
              <div slot="column1">
                <div class="black-text small-body no-indent">
                  Pictet Asset Management provides specialist investment management services through segregated accounts and mutual funds to professional investors and their clients globally. Our own clients include some of the world’s largest pension funds, financial institutions and sovereign wealth funds. 

                </div>
                <div class="black-text small-body">
                  We aim to be the investment partner of choice for our clients, by offering original strategies that prove their value over the long term, giving our clients undivided attention and being committed to excellence. In this way our clients can be confident that their future objectives will be met. 

                </div>
                <div class="black-text small-body">
                  We manage equity, fixed income, alternatives and multi-asset strategies. Within this range, our investment capabilities are pioneering and distinctive — we focus on the areas where we believe we can add value for our clients. <div class="black-text small-body no-block">We commit substantial analytical resources to our strategic capabilities: Thematic Equities, Absolute Return, Multi Asset and Emerging Markets.</div>

                </div>
              </div>
              
              <div slot="column2">
                <div class="black-text small-body">
                  Responsibility has long been central to Pictet’s thinking. We aim to be at the forefront of our industry in incorporating environmental, social and governance (ESG) issues into our investment approaches, actively exercising our rights as investors and engaging with companies themselves.
                </div>
                <div class="black-text small-body">
                   At Pictet Asset Management, our ambition is to attract and retain highly capable individuals from a wide variety of backgrounds and help them to realise their personal and professional goals. We aim to build an entrepreneurial and collegiate atmosphere, allowing ideas to mature, experience to accumulate and our people to reach their full potential. In this respect we consider it fundamental to cultivate a meritocratic environment that attracts people with unusual talent and a strong sense of team spirit.

                </div>
              </div>

              <div slot="tables">
                <div class="key-number-text--red">
                  <SectionKeyNumbers :data="keynumbersAssetManagement" :footnotes="keynumbersAssetManagementFootnotes">
                    <div slot="header-title">
                      Key Figures
                    </div>
                  </SectionKeyNumbers>
                </div>
              </div>
            
          </SectionContainer>
      </PinedSection>





      <!-- SECTION 3 - Alternative Advisors -->




      <PinedSection id="business-page-id-3" route="alternative-advisors">
          <SectionContainer class="business-pined-section">
            
              <div slot="title">
                Alternative Advisors
              </div>
              <div slot="background">
                <BackgroundContainer></BackgroundContainer>
              </div>
              <div slot="column1">
                <div class="black-text small-body no-indent">
                  Pictet Alternative Advisors provides alternative investment solutions in private equity, real estate and hedge funds, both directly and through selected external managers. Our mission is to invest in, manage and advise on alternative investment portfolios for both private and institutional clients of the Pictet Group. 
                </div>
                <div class="black-text small-body">
                  Alternative assets typically appeal to clients who are seeking diversification in their portfolio and who accept liquidity constraints in exchange for returns uncorrelated with conventional asset classes over the long-term.

                </div>
                <div class="black-text small-body">
                  Our first private equity investments were made in 1989, and in hedge funds in 1991. Since 2004, we have also invested indirectly on behalf of clients in real estate. <div class="black-text small-body no-block">By building close, long-term relationships with managers in these fields, we are able to gain access to leading funds and to attractive co-investment opportunities. </div><div class="black-text small-body no-block">In 2018, we began to expand into direct capabilities, beginning with the launch of a European real estate fund.</div>
                </div>
                <div class="black-text small-body">
                  Underscoring the Group’s commitment to private assets as a central component of Pictet’s business strategy, Pictet Alternative Advisors became a business line from 1 January 2020. 

                </div>
              </div>
              
              <div slot="column2">
                <div class="black-text small-body">
                  We collaborate with teams across the Group with distinctive expertise in alternative assets, including Pictet Asset Management — who have developed one of Europe’s leading hedge fund franchises — and the Pictet Investment Office, (PIO), which has more than three decades of experience allocating private assets to the portfolios of its entrepreneurial wealth clientele. 
                </div>
                <div class="black-text small-body">
                   Taken together, the Pictet Group’s growing investment in alternative asset resources will make an important contribution to the investment opportunities available for our clients in the coming decade.
                </div>
              </div>
            
              <div slot="tables">
                <div class="key-number-text--red">
                  <SectionKeyNumbers :data="keynumbersAlternativeAdvisors" :footnotes="keynumbersAlternativeAdvisorsFootnotes">
                    <div slot="header-title">
                      Key Figures
                    </div>
                    
                  </SectionKeyNumbers>
                </div>
              </div>

          </SectionContainer>
      </PinedSection>





      <!-- SECTION 4 - Asset Services -->




      <PinedSection id="business-page-id-4" route="asset-services">
          <SectionContainer id="business-page-id-4-offscreen-animation" class="business-pined-section">
            
              <div slot="title">
                Asset Services
              </div>
              <div slot="background">
                <BackgroundContainer backgroundColorClass="offwhite-bg"></BackgroundContainer>
              </div>
              <div slot="column1">
                <div class="black-text small-body no-indent">
                  Pictet Asset Services (PAS) manages every aspect of the asset-servicing process, employing value-adding analytics, execution services and digital front-end interfaces to provide the most competitive client experience. 
                </div>
                <div class="black-text small-body">
                  Among our principal business-to-business clients are external asset managers both for funds and private clients, as well as a range of institutional clients. <div class="black-text small-body no-block">With the capacity to offer bespoke services to the largest investing institutions, in particular in the field of data, PAS aims to be a leader in this field.</div>

                </div>
                <div class="black-text small-body">
                  Relying on a unified core platform and experienced staff, we provide cash and securities settlements, corporate actions, valuation and reporting in a consistent way across our multiple booking centres.

                </div>
                <div class="black-text small-body">
                  To enable us to offer an integrated solution for mutual funds, we run dedicated third-party management companies that handle relationships with regulators and provide fund governance services in addition to fund administration and transfer-agent services. <div class="black-text small-body no-block">We have related capabilities in investment control, performance measurement and risk management.</div>
                </div>
              </div>
              
              <div slot="column2">
                <div class="black-text small-body">
                  We also offer round-the-clock execution capabilities through a single point of entry across asset classes — including equities, bonds, foreign exchange, derivatives and mutual funds. 

                </div>
                <div class="black-text small-body">
                  As the Pictet Group does not have any investment banking activities, we can always act without conflicts of interest and to the best advantage of our clients.

                </div>
              </div>
            

              <div slot="tables">
                <div class="key-number-text--red">
                  <SectionKeyNumbers :data="keynumbersAssetServices" :footnotes="keynumbersAssetServicesFootnotes">
                    <div slot="header-title">
                      Key Figures
                    </div>
                  </SectionKeyNumbers>
                </div>
              </div>

          </SectionContainer>
      </PinedSection>

    </SectionCover>

    <div class="space-for-video half half--mobile">
      <div ref="triggerEnd" class="spacer"></div>
    </div>

  </PinedVideo>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import SectionContainer from '@/components/section-container'
import PinedVideo from '@/components/pinned-video-container'
import SectionCover from '@/components/section-cover'
import PinedSection from '@/components/pined-section-container'
import BackgroundContainer from '@/components/section-background-container'
import OffscreenTransitionContainer from '@/components/section-offscreen-container'
import { ScrollScene } from 'scrollscene'
import gsap from 'gsap'
import {keynumbersWealthManagement, keynumbersAssetManagement, keynumbersAlternativeAdvisors, keynumbersAssetServices} from '@/json/business'
import {keynumbersWealthManagementfootnotes, keynumbersAssetManagementFootnotes, keynumbersAlternativeAdvisorsFootnotes, keynumbersAssetServicesFootnotes} from '@/json/business'
import SectionKeyNumbers from '@/components/section-key-numbers'

var video = {id: "video3", src: 'our-business.mp4'}

export default {
  name: 'business',
  mixins: [storeWatchMixin],
  components: {
    SectionContainer,
    PinedVideo,
    SectionCover,
    SectionKeyNumbers,
    PinedSection,
    BackgroundContainer,
    OffscreenTransitionContainer
  },
  data(){
    return {
      video,
      keynumbersWealthManagement: keynumbersWealthManagement,
      keynumbersAssetManagement: keynumbersAssetManagement,
      keynumbersAlternativeAdvisors: keynumbersAlternativeAdvisors,
      keynumbersAssetServices: keynumbersAssetServices,

      keynumbersWealthManagementfootnotes,
      keynumbersAssetManagementFootnotes,
      keynumbersAlternativeAdvisorsFootnotes,
      keynumbersAssetServicesFootnotes
    }
  },
  mounted (){
    
        var pinedSectionSlideOutTimline = gsap.timeline({paused: true})
                            // .set(".business-pined-section",  {x: '0vw', ease: "power1.in"}) 
                            // .to(".business-pined-section",  {duration: 1, x: '100vw', ease: "power1.in"}, 'sync') 
                            .to("#business-page-nav",  {duration: 1, height: 30, ease:'power3.inout'}, 'sync')
                            // .to("#navi-line2",  {duration:1, scaleY: 1, transformOrigin:"0% 0%", ease:'power3.inout'}, 'sync')
                            .to('#business-page-id-4-offscreen-animation',  {duration: 1, x: "100vw", opacity: 1, ease: 'power1.inOut'}, 'sync')
                            
        new ScrollScene({
                          triggerElement: this.$refs.triggerEnd,
                          duration: "100%",
                          triggerHook: 1,
                          gsap: {
                              timeline: pinedSectionSlideOutTimline
                          }
                      }).Scene
                      // .Scene.setPin("#business-page-start" , {pushFollowers: false})
                      .setClassToggle("#business-page-id-3", "u-v-hidden")
                      // .on("enter", () => {console.log ("enter b")})
                      // .on("leave", () => {console.log ("leave b")})
                      
                      // .addIndicators("business")
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
