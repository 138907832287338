<template>
    <div ref="videoContainer">
       
      <portal ref="videoPlayer"  to="video-portal">

        <div :id="'video-container-'+name" :class="{'pinned-video-container--overflow': useOverflow}">
          <div :id="'video-wrapper-'+name" class="pinned-video-container">
            
            <video v-if="!isIE" ref="player" :id="'video-'+name" :poster="responsivePosterPath"  playsinline paused muted loop >
                <source :src="responsiveVideoPath"/>
                <source :src="webm"/>
            </video>
            <div v-else>
              <img class="video-poster" :id="'video-'+name" :src="responsivePosterPath" alt="" srcset="">
            </div>

          </div>
        </div>

      </portal>
      <slot>
        <!-- childrens -->
      </slot>
      <div :class="{'video-end-trigger-container': useOverflow}">
        <div ref="videoLeaveTrigger" class="video-end-trigger-offseted">
          <!-- this will trigger video section end to pause/remove video  -->
        </div>
      </div>
        
    </div>
</template>
<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import { ScrollScene } from 'scrollscene'
import Bowser from "bowser";

export default {
    
  name: 'pined-section-container',
  mixins: [storeWatchMixin],
  props: {
    path: String,
    name: String,
    route: String,
    useOverflow: Boolean,
    switchDuration: {
      type: Number,
      default: 200
    }
  },
  data(){
    return {
        responsivePosterPath: null,
        responsiveVideoPath: null,
        isVisible : false,
        state: 'enterTop',
        opacity: 0,
        visibility: null,
        isIE: false,
        lastPosition: null,
        size:{
          width: 0,
          height: 0,
        }
    };
  },
  components: {
    
  },
  beforeMount(){

    this.detectBrowser();

  },
  mounted (){
    if(this.name == "video-intro")
      this.opacity = 1;
      
    this.initScene();
    this.onResize ();

    window.eventBus.$on('video-portal-changed', this.onResize)

    $(window).scroll((e) => {
      

      this.onScroll()

    })
      this.onScroll()

  },
  methods: {

    detectBrowser(){
      const browser = Bowser.getParser(window.navigator.userAgent);
      this.isIE = browser.getBrowser().name == "Internet Explorer";
    },

    updateSceneDuration (){

      // this.sceneProgress.duration ($(this.$refs.videoContainer).outerHeight())

      // if (this.useOverflow)
      //   this.sceneOverflow.duration (this.windowHeight)
      
    },
    onResize (){
      
      var player = `#video-${this.name}`
      var offset = 200
      var sw = this.windowWidth / this.videoWidth;
      var sh = (this.windowHeight + offset) / this.videoHeight;

      this.size.width   = $(this.$refs.videoContainer).outerWidth();
      this.size.height  = $(this.$refs.videoContainer).outerHeight();
      
      if (document.getElementById(`video-${this.name}`))
        if (sw > sh) {
          gsap.set(player, {width : Math.ceil(this.videoWidth * sw)})
          gsap.set(player, {height : Math.ceil((this.videoHeight) * sw)})
        }else{
          gsap.set(player, {width : Math.ceil(this.videoWidth * sh)})
          gsap.set(player, {height : Math.ceil((this.videoHeight) * sh)})
        }
      
      this.$nextTick (() => {
        this.updateSceneDuration();
      })
      this.onScroll()
    },
    onScroll(){
      
      var offset = 200;
      var scrollTop = $(window).scrollTop();
      var scrollBottom = $(window).scrollTop() + this.windowHeight;
      var progress = 0
      
      if (scrollBottom > $(this.$refs.videoContainer).offset().top && scrollTop < $(this.$refs.videoContainer).offset().top + this.size.height){
        progress = (scrollBottom - $(this.$refs.videoContainer).offset().top )/ (this.size.height)
        this.visibility = "visible"
        // gsap.set(`#video-${this.name}`, {visibility: "visible"})
        
      }else{
        if (this.visibility == "hidden" && $(`#video-${this.name}`).css('visibility') != 'hidden')
          gsap.set(`#video-${this.name}`, {visibility: "hidden"})

          this.visibility = "hidden"
        
        return;
        // gsap.set(`#video-${this.name}`, {visibility: "hidden"})
      }
      
      var e = {
        progress
      }

      var translateY = ((e.progress-0.5)  * (offset * 0.8));
      var videoHeight = $(`#video-${this.name}`).height();
      var procent = $(`#video-${this.name}`).height() >= 0 ? -50 - translateY / $(`#video-${this.name}`).height() * 100 : -50;
      
      // if (this.isIE)
      //   procent = -50
      
      if (this.lastPosition != procent)
        gsap.set(`#video-${this.name}`, { x: "-50%", y : `${ procent }%`})

      this.lastPosition = procent

      this.updateOverflow(e)
      
    },
    togglePlayer () {
      
      if (!this.isIE) {
        if (this.isVisible) {

          this.$refs.player.play();
          
        }else{

          this.$refs.player.pause();

        }
      }
    },
    
    initScene(){

      // this.sceneProgress = new ScrollScene({
      //                         triggerElement: this.$refs.videoContainer,
      //                         triggerHook: 1,
      //                         duration: $(this.$refs.videoContainer).outerHeight()
      //                       }).Scene
      //                       .on('progress', (e) => {
      //                         this.onScroll (e);
      //                       })

      var sceneEnter = new ScrollScene({
                              triggerElement: this.$refs.videoContainer,
                              triggerHook: 1
                              
                            })
      
      sceneEnter.Scene.on("enter", () => { this.state = "enterTop"; this.isVisible = true; this.updateHash()})
      sceneEnter.Scene.on("leave", () => { this.state = "leaveTop"; this.isVisible = false;})

      var sceneLeave = new ScrollScene({
                              triggerElement: this.$refs.videoLeaveTrigger,
                              triggerHook: 1,
                          })

      sceneLeave.Scene.on("enter", ()=> { this.state = "leaveBottom"; this.isVisible = false;})
      sceneLeave.Scene.on("leave", ()=> { this.state = "enterBottom"; this.isVisible = true; this.updateHash()})
    


      
      // Scene for overflow mask
      if (this.useOverflow){
        
        this.opacity = 1
        // this.sceneOverflow = new ScrollScene({
        //                         triggerElement: this.$refs.videoLeaveTrigger,
        //                         triggerHook: 1,
        //                         duration: this.windowHeight
        //                     }).Scene

        // this.sceneOverflow.on("enter", (e) => this.updateOverflow(e, true))
        // this.sceneOverflow.on("leave", (e) => this.updateOverflow(e, true))
        // this.sceneOverflow.on("progress", (e) => this.updateOverflow(e))
      }else{

        var tlEnter = gsap.timeline({paused: true})
          .to(this, {duration : 1, opacity: 1})

        var alphaEnter = new ScrollScene({
                                triggerElement: this.$refs.videoContainer,
                                triggerHook: 1,
                                duration: this.switchDuration,
                                gsap: {
                                  timeline: tlEnter
                                }
                            })

        var tlLeave = gsap.timeline({paused: true})
          .to(this, {duration : 1})
          .set(this, {visibility: 'hidden'})

        var alphaLeave = new ScrollScene({
                                triggerElement: this.$refs.videoLeaveTrigger,
                                triggerHook: 1,
                                duration: 200,
                                gsap: {
                                  timeline: tlLeave
                                }
                            }) 
      }
    },
    updateOverflow (e, leave = false){
      
      if (leave) {
        
        if (e.progress == 0)
          gsap.set('#video-container-' + this.name, { height: '100vh'})
        else if (e.progress == 1)
          gsap.set('#video-container-' + this.name, { height: '0vh'})

        return 
      }

      var progressWithOvelap = Math.min(1, e.progress * 55);
      var height = $(this.$refs.videoLeaveTrigger).offset().top - $(window).scrollTop() - 200 * progressWithOvelap;

      if (height < 0)
        height = 0;

      if (this.useOverflow)
        gsap.set('#video-container-' + this.name, { height: height + "px"})
      
    }
  },
  computed:{
    webm(){
      
      if (this.responsiveVideoPath)
        return this.responsiveVideoPath.split('.')[0] + '.webm';
      else 
        return null
    },
    videoHeight() {
      return ((this.windowWidth >= 992) ? 1028 : 812);
    },
    videoWidth() {
      return ((this.windowWidth >= 992) ? 1920 : 374);
    },
  },
  watch: {
    visibility: function (value){
      
      gsap.set(`#video-${this.name}`, { visibility: value})
      
    },
    opacity: function (value){
      
      gsap.set(`#video-${this.name}`, { opacity: value})
      
    },
    isVisible: function (){

      this.togglePlayer();

    },
    windowWidth : function (){

      var path = "/media/" + ((this.windowWidth >= 992) ? "d-" + this.path : "m-" + this.path);
      this.responsiveVideoPath = path
      this.responsivePosterPath = "/media/posters/" + ((this.windowWidth >= 992) ? "d-" + this.path.split('.')[0] + ".jpeg" : "m-" + this.path.split('.')[0] + ".jpeg") 
      
    }
  }
}
</script>

<style lang="scss">

.pinned-video-container{

  position: absolute; 
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

}

.pinned-video-container--overflow{
  
  overflow: hidden;
  z-index: 100;

  position: absolute; 
  height: 100vh; 
  width: 100vw;
  
}

.video-end-trigger-container{
  position: relative;
  .video-end-trigger-offseted{
    position: absolute;
    top: -100px;
  }
}

</style>