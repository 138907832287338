import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    updateURLonScroll: false,
    isAppMounted: false,
    navigation: {
      isOpened: false,
      isPopOpened: false
    },
    dimmensions: {
      windowSize: {
        width: 0,
        height: 0
      }
    }
  },
  mutations: {
    setUpdateURLonScroll (state, value) {
      state.updateURLonScroll = value
    },
    setAppMounted (state, isMounted) {

      state.isAppMounted = isMounted

    },
    popupVisbilityState (state, isOpened) {

      state.navigation.isPopOpened = isOpened

    },
    windoSize (state, size) {

      state.dimmensions.windowSize.width = size.width;
      state.dimmensions.windowSize.height = size.height;

    },
  },
  actions: {
    setUpdateURLonScroll (context, value) {
      context.commit('setUpdateURLonScroll', value);
    },
    setAppMounted (context, isMounted) {
      context.commit('setAppMounted', isMounted);
    },
    setPopupVisbilityState (context, isOpened) {
      context.commit('popupVisbilityState', isOpened);
    },
    setWindowSize (context, size) {
      context.commit('windoSize', size);
    },
  },
  modules: {
  },
  getters: {
    windowSize: state => {
      return state.dimmensions.windowSize
    },
    isPopOpened: state => {
      return state.navigation.isPopOpened
    },
    isAppMounted: state => {
      return state.isAppMounted
    },
    updateURLonScroll: state => {
      return state.updateURLonScroll
    }
  }
})
