<template>
    <div class="u-disabled-interaction">
        <div class="row"><div class="col-md-4 col-xl-3 d-none d-md-block"></div><div class="col-12 col-md-8 col-xl-9 whitespace"></div></div>
        <div class="row"><div class="col-md-4 col-xl-3 d-none d-md-block"></div><div class="col-12 col-md-8 col-xl-9 backgroud_fix"></div></div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    
</style>