<template>
  <PinedVideo :path="video.src" name="ambition">
    
    <SectionCover text="Our Ambition 2025" name="ambition"  route="ambition">
      <div class="space-for-video--mobile"></div>
      <PinedSection id="page4">
        <OffscreenTransitionContainer>
        <div class="page-text ambition-container ambition-pined-section">
            <BackgroundContainer backgroundColorClass="dark-bg"></BackgroundContainer>

            
            <div class="container container-nopadding">    
                <div class="row ">
                    
              <div class="col-12 col-md-5 col-xl-4"></div>
                <div class="col-12 col-md-7">
                  <h3 class="page-header" style="color: white;">The seven themes</h3></div>
                </div>        
                <div class="row">
                    <div class="col-sm-2 col-md-5 col-xl-4 d-none d-sm-block"></div>
                    <div class="col-12 col-sm-10 col-md-7 col-xl-7">
                      <h4 class="page-big-paragraph--nomargin">
                        As the new decade opens, we expect a growing transformation in macroeconomics, technology and society. Across these three areas, we have identified seven themes that we believe will shape the business of finance in the coming five years and more. Certainly, the ramifications of the coronavirus crisis will deeply affect the way these themes play out in the coming years, intensifying a number of the trends already identified here. Each theme offers opportunities — and risks — for investors, our clients and the way we do business. 
                      </h4>
                    </div>
                </div>
                <!-- 1 -->
                <div class="row">
                    <div class="col-sm-5 col-md-6 col-xl-7 d-none d-sm-block"></div>
                    <div class="offset-3 col-9 col-sm-6 offset-sm-1 col-md-5 col-xl-3  offset-md-1 ">
                      <p class="small-body smallmargin grey">

                      IN THE MACRO ENVIRONMENT we envisage profound changes, represented in the first four of the seven themes. First, <span class="emphasized">THE RISE OF ASIA</span>, led by China, which is already the leading global economic superpower. Before long, the region will become a financial superpower too. By 2030, Asia (including Japan) will account for 40% of the world’s wealth. It will increasingly attract global capital flows and investments, while its current under-representation in leading financial indices looks certain to change.
                      
                      </p>
                    </div>
                </div>
                <!-- 2 -->
                <div class="row">
                    <div class="col-sm-5 col-md-5 col-xl-6 d-none d-sm-block"></div>
                    <div class="offset-1 col-9 offset-sm-0 col-sm-6  col-md-5 col-xl-3 offset-md-1">
                      <p class="small-body clearmargin grey">
                        Second, persistently slow growth and historically low interest rates elsewhere are driving investors to higher-yielding and risk-diversifying <span class="emphasized">PRIVATE ASSETS</span>. Such demand is being satisfied by a large and rising supply of firms aiming to turn or stay private, attracted by fewer short-term pressures and lighter regulation. As this tendency grows, private markets generally — including private equity, private debt and real estate — will no longer be considered alternative.
                        </p></div>
                </div>
                <!-- 3 -->
                <div class="row">
                    <div class="col-sm-5 col-md-6 col-xl-7 d-none d-sm-block"></div>
                    <div class="offset-3 col-9 col-sm-6 offset-sm-1 col-md-5 col-xl-3  offset-md-1 ">
                      <p class="small-body clearmargin grey">
                      Third, the idea that deleveraging has occurred since the 2008 global financial crisis is a myth. In fact the debt market may be the catalyst for the next major episode of <span class="emphasized">financial instability</span> — the result of a two-decade long build-up in government, corporate and household debt that may ultimately become unserviceable. If central banks continue to print money to avert a crisis, this could lead to a new debt climax, currency instability or a surge in the value of real assets. The next downturn would not then be cyclical, but one with a major long-term impact on the economy and the financial industry.

                      </p></div>
                </div>
                <!-- 4 -->
                <div class="row">
                   <div class="col-sm-5 col-md-5 col-xl-6 d-none d-sm-block"></div>
                    <div class="offset-1 col-9 offset-sm-0 col-sm-6 col-md-5 col-xl-3 offset-md-1">
                      <p class="small-body clearmargin grey">
                      Fourth, a downturn or fully fledged financial crisis would reinforce existing tendencies towards greater <span class="emphasized">tax transparency, populism and deglobalisation</span>. Governments are already contemplating a major review of global taxation in which wealthy individuals and multinationals are likely to be pursued systematically. Such reform will radically disrupt where and how businesses operate. Digital businesses will be a primary target, but so will the service sector, and the financial industry in particular. These trends will advance the deglobalisation already manifest in the rise of political and economic populism. 


                      </p></div>
                </div>
                <!-- 5 -->
                <div class="row">
                    <div class="col-sm-5 col-md-6 col-xl-7 d-none d-sm-block"></div>
                    <div class="offset-3 col-9 col-sm-6 offset-sm-1 col-md-5 col-xl-3  offset-md-1 ">
                      <p class="small-body  grey">
                        IN TECHNOLOGY, disruption is at its most fundamental. In particular, fifth, <span class="emphasized">AI and machine learning</span> are leading an accelerating digital advance which will dislocate our industry and our relationship with clients. As data becomes ever more valuable, data science that uses these technologies will transform the way investment information is gathered, analysed, employed and presented. Also, clients will expect the most advanced digital solutions. 
                        </p>
                        
                      </div>
                    <div class="col-1 d-none d-md-block"></div>
                </div>
                <!-- 6 -->
                <div class="row">
                    <div class="col-sm-5 col-md-5 col-xl-6 d-none d-sm-block"></div>
                    <div class="offset-1 col-9 col-sm-6 offset-sm-0 col-md-5 col-xl-3 offset-md-1">
                      <p class="small-body clearmargin grey">
                        Yet, sixth, technology is dual-use: for financial institutions, <span class="emphasized">cyber is the new systemic risk</span>. Resistance to cyber-attacks that seek to undermine the most basic banking services will become a cardinal test of resilience, as will the security afforded by a robust balance sheet. Client concerns about data privacy will only intensify. In the future, financial institutions, already trusted as data collectors, may find a new role as data custodians.
                      </p>
                    </div>
                    <div class="col-3 d-none d-md-block"></div>
                </div>
                <!-- 7 -->
                <div class="row">
                    <div class="col-sm-5 col-md-6 col-xl-7 d-none d-sm-block"></div>
                    <div class="offset-3 col-9 col-sm-6 offset-sm-1 col-md-5 col-xl-3  offset-md-1 ">
                      <p class="small-body  grey">
                        
                        IN SOCIETY, seventh, changes are redefining client behaviour, what they expect from their investments and from the wider economy. <span class="emphasized">A more responsible form of capitalism</span> will prevail; companies will be driven by a wider purpose, rather than the single-minded creation of shareholder value. Although the gap between the top tier of the population and the middle class is forecast to widen, the most fortunate are likely to raise their commitment to philanthropy and responsible investments. Equally, with damaging climate change ahead, investors are recognising that environmental and social challenges will critically affect business results in the years to come — and that the future belongs to businesses that find the best solutions to these challenges. Finance will be central in the move towards a more regenerative and circular business model.

                      </p>
                      
                    </div>
                    <div class="col-1 d-none d-md-block"></div>
                </div>

                <!-- 8 -->

                <div class="row">
                    <div class="col-sm-2 col-md-5 col-xl-4 d-none d-sm-block"></div>
                    <div class="col-12 col-sm-10 col-md-7 col-xl-6">
                      
                      <h4 class="page-big-paragraph">How we respond to these changes will determine not only our ability to serve our clients but also, ultimately, to prosper together in the world of tomorrow. These are the seven themes that will inform our strategic choices in the years to 2025 and beyond. </h4></div>
                    <div class="col-2 d-none d-md-block"></div>
                </div>
                <section-bottom-fill></section-bottom-fill>
            </div>
        </div>
        </OffscreenTransitionContainer>
      </PinedSection>

    </SectionCover>

    <div class="space-for-video half--mobile">
        <div ref="trigger" class="spacer"></div>
    </div>

  </PinedVideo>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import sectionContainer from '@/components/section-container'
import PinedVideo from '@/components/pinned-video-container'
import SectionCover from '@/components/section-cover'
import sectionBottomFill from '@/views/section-bottom-fill'
import PinedSection from '@/components/pined-section-container'
import OffscreenTransitionContainer from '@/components/section-offscreen-container'
import BackgroundContainer from '@/components/section-background-container'
import { ScrollScene } from 'scrollscene'

import gsap from 'gsap'

var video = {id: "video3", src: 'ambition.mp4'}

export default {
  name: 'ambition',
  mixins: [storeWatchMixin],
  components: {
    sectionContainer,
    PinedVideo,
    SectionCover,
    PinedSection,
    BackgroundContainer,
    OffscreenTransitionContainer,
    sectionBottomFill
  },
  data(){
    return {
      video
    }
  },
  mounted (){
    
        var pinedSectionSlideOutTimline = gsap.timeline({paused: true})
                            .to(".ambition-pined-section", 1, {x: '100vw', ease: "power1.in"}, 0) 

        new ScrollScene({
                          triggerElement: this.$refs.trigger,
                          duration: '100%',
                          triggerHook: 1,
                          gsap: {
                              timeline: pinedSectionSlideOutTimline
                          }
                      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .ambition-container{
    p:first-child{
      margin-top: 30px;
    }
    .small-body{
      color: #a0a0a1;
      text-indent: 0;
    }

    .clearmargin{
      margin: 0 !important;
    }
  }
</style>
