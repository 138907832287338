
<template>
    <div class="cover-video u-disabled-interaction">
        
        <portal-target name="video-portal" multiple @change="handleUpdate">
            <!-- video  will be rendered here -->
        </portal-target>
         
    </div>
</template>
<script>
export default {
  name: 'video-portal',
  methods: {
      handleUpdate(){
          
          window.eventBus.$emit('video-portal-changed')
          
      }
  }
}
</script>

<style lang="scss">
    
</style>