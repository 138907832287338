<template>
    <div class="page-text">
      <slot name="background"></slot>
      <div class="container container-nopadding" >            
          <div class="row">
                <div class="col-12 col-md-5 col-xl-4"></div>
                <div class="col-12 col-md-7">
                    <h3 class="page-header">
                        <slot name="title">
                            <!-- Title text -->
                        </slot>
                    </h3>
                </div>
          </div>
          <div class="row">
                <slot name="upper-slot">
                    
                </slot>
          </div>
          <div class="row">
                <div class="col-md-5 col-xl-4 d-none d-md-block"></div>
                <div class="col-3 col-md-2 d-none d-sm-block terms-text-wrapper">
                    <terms-text>
                        <slot name="terms-text">
                            <!-- Terms text on mobile -->
                        </slot>
                    </terms-text>
                    <slot name="terms-text-list">
                        <!-- Terms text on mobile -->
                    </slot>
                </div>
                <div class="col-12 col-sm-9 col-md-5 col-xl-3 position-relative">
                    <div ref="column1">
                        <slot name="column1">
                            <!-- Column 1 text -->
                        </slot>
                    </div>
                    <div ref="column1-origin-text" class="position-absolute invisible"></div>
                </div>
                <div class="col-12 col-sm-9 col-md-5 col-xl-3 offset-sm-3 offset-md-7 offset-xl-0 position-relative">
                    <div ref="column2">
                        <slot ref="column2"  name="column2">
                            <!-- Column 2 text -->
                        </slot>
                    </div>
                    <div ref="column2-origin-text" class="position-absolute invisible"></div>
                </div>
          </div>


            <div class="row">
                <div class="col-12 col-md-5 col-xl-4"></div>
                <div class="col-8 col-md-3 d-block d-sm-none ">
                    <terms-text>
                        <slot name="terms-text">
                            <!-- Terms text on mobile -->
                        </slot>
                    </terms-text>
                    <slot name="terms-text-list">
                        <!-- Terms text on mobile -->
                    </slot>
                </div>
            </div>

          <slot name="gallery">
            <!-- GALLERY -->
          </slot>


          <slot name="tables">
            <!-- KEY NUMBERS OR TABLE -->
          </slot>

          <section-bottom-fill></section-bottom-fill>
      </div>
    </div>
</template>

<script>
import sectionBottomFill from '@/views/section-bottom-fill'
import TermsText from '@/views/terms-text'
import storeWatchMixin from '@/mixins/storeWatchMixin'
export default {
    components:{
        sectionBottomFill,
        TermsText
    },
    mixins: [storeWatchMixin],
    data(){
        return {
            isMoutend: false,
            textReseted: true
        }
    },
    mounted() {
        
        this.makeCopyofOriginColumns();
        this.isMoutend = true;

        this.onResize()
    },
    methods: {
        makeCopyofOriginColumns (){
            
            $(this.$refs["column1-origin-text"]).append ($(this.$refs.column1).clone());
            $(this.$refs["column2-origin-text"]).append ($(this.$refs.column2).clone());

        },
        makeTextEaseToRead (){

            while (this.isColumn1ToBig()){

                var paragrphs = $(this.$refs.column1).find(".small-body");
                
                $(this.$refs.column2).prepend(paragrphs[ paragrphs.length - 1 ]);
                
                this.textReseted = false;
            }

        },
        isColumn1ToBig (){
            
            return $(this.$refs.column1).height() > window.innerHeight - 60;

        },
        revertColumns (){
            
            if (this.textReseted)
                return;

            $(this.$refs.column1).empty ()
            $(this.$refs.column1).append ($(this.$refs["column1-origin-text"]).contents().clone())

            $(this.$refs.column2).empty ()
            $(this.$refs.column2).append ($(this.$refs["column2-origin-text"]).contents().clone())
            
            this.textReseted = true;
        },  
        onResize (windowWidth, windowHeight){

            if (this.isMoutend) {
                
                this.revertColumns ();

                if (windowWidth > 1200 & this.isMoutend) {
                    
                    this.makeTextEaseToRead();
                    
                }else{
                    
                }

            }

            this.$emit('textChange', $(this.$refs.column1).height());
        }
    }
}
</script>

<style lang="scss">
    
</style>