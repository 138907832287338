
export const keynumbersWealthManagement = {
    rows: [
            { value: "234", className: 'keynumber-6', description: "assets under<br>management<sup>1</sup>", unit: "BN", currency: "CHF" },
            { value: "22", className: 'keynumber-3', description: "offices worldwide", unit: "", currency: "" },
            { value: "591", className: 'keynumber-6-wm', description: "investment<br>professionals<sup>2</sup>", unit: "", currency: "" },
            { value: "352", className: 'keynumber-7-wm', description: "of which", descriptionSecond:"private<br>bankers<sup>3</sup>", unit: "", currency: "" },
            { value: "1029", className: 'keynumber-1', description: "full time equivalent<br>employees", unit: "", currency: "" }
        ]
}
export const keynumbersWealthManagementfootnotes = [

  ['The term ‘Assets under management or custody’ excludes double counting. It represents',' the assets of private and institutional clients looked after by the Pictet Group. These assets may be managed through individual discretionary mandates, benefit from value-added services such as investment advice, or simply be under deposit. '],
  ['Investment professionals are defined as all staff whose principal activity is in',' the area of investment research and analysis, portfolio management, product management, trading, wealth planning, investment advisory, sales, marketing or client relationship management, or the management of such activities. Not included are those occupying, for example, pure operational, mid-office, compliance or risk management functions.'],
  ['Private bankers are defined as all staff whose principal activity is to actively',' advise clients on wealth management solutions. They ensure the quality of the client experience, while some are responsible for new client acquisition.']

]










export const keynumbersAssetManagement = {
  rows: [
          { value: "1980", className: 'keynumber-11', description: "asset manager<br>since", unit: "", currency: "" },
          { value: "202", className: 'keynumber-2', description: "assets under management<sup>1</sup>", unit: "BN", currency: "CHF" },
          { value: "17", className: 'keynumber-12', description: "&nbsp;&nbsp;&nbsp;offices<br>worldwide", unit: "", currency: "" },
          { value: "399", className: 'keynumber-16', description: "investment<br>professionals<sup>2</sup>", unit: "", currency: "" },
          { value: "7", className: 'keynumber-13', description: "&nbsp;&nbsp;&nbsp;&nbsp;investment<br>centres", unit: "", currency: "" },
          { value: "1003", className: 'keynumber-1', description: "full time equivalent<br>employees", unit: "", currency: "" }
      ]
}
export const keynumbersAssetManagementFootnotes = [

  ['The term ‘Assets under management or custody’ excludes double counting. It represents the', ' assets of private and institutional clients looked after by the Pictet Group. These assets may be managed through individual discretionary mandates, benefit from value-added services such as investment advice, or simply be under deposit. '],
  ['Investment professionals are defined as all staff whose principal activity is in the area of', ' investment research and analysis, portfolio management, product management, trading, wealth planning, investment advisory, sales, marketing or client relationship management, or the management of such activities. Not included are those occupying, for example, pure operational, mid-office, compliance or risk management functions.']

]









export const keynumbersAlternativeAdvisors = {
  rows: [
          { value: "28", className: 'keynumber-1', description: "assets under<br>management<sup>1</sup>", unit: "BN", currency: "CHF" },
          { value: "31", className: 'keynumber-1', description: "years of experience<br>in&nbsp;alternatives", unit: "", currency: "" },
        
          { value: "87", className: 'keynumber-7', descriptionSecond: "full time equivalent<br>employees", unit: "", currency: "" },
          { value: "46", className: 'keynumber-1', description: "investment<br>professionals<sup>2</sup>", unit: "", currency: "" },
        
          { value: "1992", className: 'keynumber-8', description: "first co-investment", unit: "", currency: "" },
          { value: "", description: "", unit: "", currency: "" }
      ]
}

export const keynumbersAlternativeAdvisorsFootnotes = [

  ['This figure is included in the assets under management of Pictet Wealth Management, Pictet Asset Management and', ' Pictet Asset Services. The term ‘Assets under management or custody’ excludes double counting. It represents the assets of private and institutional clients looked after by the Pictet Group. These assets may be managed through individual discretionary mandates, benefit from value-added services such as investment advice, or simply be under deposit.'],
  ['Investment professionals are defined as all staff whose principal activity is in the area of investment', ' research and analysis, portfolio management, product management, trading, wealth planning, investment advisory, sales, marketing or client relationship management, or the management of such activities. Not included are those occupying, for example, pure operational, mid-office, compliance or risk management functions.']
]














export const keynumbersAssetServices = {
  rows: [
          { value: "186", className: 'keynumber-7', descriptionSecond: "assets in custody<sup>1</sup>", unit: "BN", currency: "CHF" },
          { value: "86", className: 'keynumber-14', description: "of which", descriptionSecond: "are fund services", unit: "", unit: "BN", currency: "CHF" },
          { value: "1", className: 'keynumber-15', description: "global<br>platform ", unit: "", currency: "" },
          { value: "6", className: 'keynumber-10', description: "offices<br>worldwide", unit: "", currency: "" },
          { value: "195", className: 'keynumber-16', description: "full time equivalent<br>employees<sup>2</sup>", unit: "", currency: "" },
          { value: "", description: "", unit: "", currency: "" }
      ]
}

export const keynumbersAssetServicesFootnotes = [

  ['This figure does not include assets held in custody for internal clients, i.e., Pictet Wealth Management and Pictet Asset Management'],
  ['This figure does not include the 1,402 full-time equivalent employees of PAS Operations and', ' IT, now part of a unit called Technology & Operations, or the 175 full-time equivalent employees of Pictet Trading & Sales']

]
