<template>
  
    <pined-video :path="video.src" name="acting-responsibly">

      <section-cover text=" Acting Sustainably" name="acting-responsibly" id="acting-responsibly-page-start"  route="acting-sustainably">
        
        <div class="space-for-video">
          <div ref="triggerStart" class="spacer"></div>
        </div>
        <div class="space-for-video half"></div>
        
        <SectionContainer @textChange="onTextChange" class="dark-bg acting-responsibly-wrapper" id="page1-content">
            
          <div slot="title">
            <span style="color: white;">
              Thinking and acting <br>sustainably
            </span>
          </div>

          <div slot="gallery">
            
            <div style="height: 90px"></div>
            <div class="row">
              <div class="col-12 col-md-7 col-xl-6 offset-md-5 offset-xl-6">
                <div class="row">
                  <div class="col-12"><p class="small-body no-indent">Prix Pictet — Joana Choumali was the winner of the Prix Pictet Hope cycle with her series <i>Ça va aller</i>, taken after the terrorist attacks in Grand-Bassam, Côte d’Ivoire, of Sunday 13 March 2016<br><br></p></div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <img ref="image" src="@/assets/images/acting-responsibly.jpg" class="partners-image">
                  </div>
                </div>
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6"><p class="captions"><i>Untitled 2019</i>, A unique print from the approximately 180 unnumbered works of the series <i>Ça va aller</i> (It will be okay). 24cm x 24cm, digital inkjet print on cotton canvas, hand embroidered with cotton, lurex and wool thread.</p></div>
                </div>
              </div>
            </div>

          </div>
          <div ref="textColumn" slot="column1">
            <div class="small-body no-indent">
              An instinctive sense of responsibility has always defined Pictet’s thinking. As the climate crisis has intensified and the notion of corporate purpose taken hold, it is our duty to intensify our efforts in this direction.  
            </div>
            <div class="small-body">
              Twenty years ago we were an early mover in sustainable investment strategies. We now intend to extend the integration of environmental, social and governance (ESG) principles into at least 80% of discretionary mandates by end 2020, rising to 100% by 2025. We also have a programme of active ownership and engagement with selected issuers. <div class="small-body no-block">Ambitions and precise targets for our investment activities and our own operations will be published by end 2020, in line with the UN Principles for Responsible Banking, signed last year.</div>
            </div>
          </div>

          <div slot="column2">
            <p class="small-body">
              In 2020 the partners announced that balance sheet exposure<sup>1</sup> to carbon-intensive companies<sup>2</sup> would be eliminated by year end, from a level of around CHF 250 million at 31 December 2019.
            </p>
            <p class="small-body">
              This year the Pictet Group Foundation begins operation with endowment capital of CHF 45 million. It will focus on environmental protection and disadvantaged children and youth.
            </p>
            <p class="small-body">
              The Pictet Group has always considered not only the interests of the present, but also of future generations. This is the essence of responsible thinking, and the highest contribution we can make.
            </p>

          </div>

          <div slot="terms-text-list" class="acting-responsibly-footnotes">
            <div class="footnotes"  >
              <terms :footnotes="footnotes"></terms>
            </div>
          </div>

        </SectionContainer>

      </section-cover>

    </pined-video>

</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import SectionContainer from '@/components/section-container'
import PinedVideo from '@/components/pinned-video-container'
import SectionCover from '@/components/section-cover'
import PinedSection from '@/components/pined-section-container'
import BackgroundContainer from '@/components/section-background-container'
import OffscreenTransitionContainer from '@/components/section-offscreen-container'
import Terms from '@/views/terms'
import { ScrollScene } from 'scrollscene'

var video = {id: "video4", src: 'responsibility.mp4'}

export default {
  name: 'Acting-Responsibly',
  mixins: [storeWatchMixin],
  components: {
    SectionContainer,
    PinedVideo,
    SectionCover,
    PinedSection,
    Terms,
    BackgroundContainer,
    OffscreenTransitionContainer
  },
  data(){
    return {
      video,
      footnotes: [
        ['Balance sheet exposure includes positions in Treasury, in a hedging book of derivatives and structured products, as well as seed investments'],
        ['Companies gaining more than 25 per cent of their revenues from producing or extracting oil and gas or thermal coal']
      ]
    }
  },
  mounted(){
    this.onResize();
  },
  methods: {
    onTextChange(height){
      
      var marginTop = Math.max(30, height - $(".footnotes").height())
      $('.acting-responsibly-footnotes').css('margin-top',  marginTop + "px")

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .acting-responsibly-wrapper{
    
    .captions{
      color: #f9f9f9;
      opacity: 0.7;
    }
    .terms-text{
      border-top: 1px solid #919191;
      color: #919191;
    }
    .terms-text-wrapper{
      padding-top: 0;
    }
    .acting-responsibly-footnotes{
      position: relative;
      @media screen and (max-width: 539px){
        margin-top: 0 !important;
      }
      
      .footnotes{
        color: #919191; 
        // position: absolute;

        @media screen and (max-width: 539px){
          
          position: static;
        }
      }
    }
  }
  .acting-responsibly-text-col{
    margin-bottom: 90px;

  }
</style>
