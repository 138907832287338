<template>
  <PinedVideo :path="video.src" name="home" :useOverflow="true" route="introduction">
    <div class="intro-wrapper">
      <div class="intro-bg-mobile-top-fill"></div>
      <div id="intro-bg" class="intro-bg white-text"></div>
      <div class="intro-bg-mobile-topbg-fix white-text"></div>
      <div class="intro-container u-disabled-interaction u-none-selectable" style="z-index: 980;">
          
      </div>



      <div class="intro-container u-disabled-interaction u-none-selectable">
        <div class="conatiner-fluid fixedTop"  style="z-index: 2">
          <div class="container  ">
            <div ref="annual-title-container" class="row annual-title-container">
              <div class="col-12">
                <h1 id="annualheader" class="pictet-group-header cover-header ">
                  <p>The Pictet Group</p>
                  <p>Annual Review</p>
                  <!-- <p class="annual-date-header">31 December 2019</p> -->
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

        

        <div class="space-for-video" id="triggerHeaderText"></div>
        <div class="intro-text">
          <div id="triggerNavi" class="spacer"></div>
          <div class="spacer"></div>
          <div class="container">            
            <div class="row">
              <div class="col-md-5 col-xl-4 d-none d-md-block"></div>
              <div class="col-12 col-md-6 col-xl-4">
                <p class="big-body">
                  To our clients, colleagues, communities and companies in&nbsp;which we invest
                </p>
              </div>
              <div class="col-12 col-md-6 col-xl-4 offset-md-5 offset-xl-0 ">
                <p class="small-body">
                  As we were preparing this sixth Annual Review, the Covid-19 pandemic has led to conditions unprecedented in peacetime. No-one knows how long such conditions will last or what the longer-term consequences will be. Especially in these circumstances, our clear responsibilities are to protect the interests of our clients and ensure the welfare of our employees. Fortunately Pictet is in a position of financial strength and prepared to weather this crisis. In our Ambition 2025 strategic plan, our focus is on investment leadership, on our strength, security and resilience as a group, and on accelerating our initiatives as a responsible firm. We want to be a force for good, a role model for this and future generations.
                </p>
                <p class="small-body">
                  Renaud de Planta<br>On behalf of the Managing Partners
                </p>
              </div>
            </div>
          </div>
          <div id="triggerHeaderHide" ></div>
        </div>
    </div>

  </PinedVideo>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import PinedVideo from '@/components/pinned-video-container'
import SectionKeyNumbers from '@/components/section-key-numbers'
import gsap from 'gsap'
import { ScrollScene } from 'scrollscene'

var video = {id: "video1", src: 'intro.mp4'}

export default {
  name: 'introduction',
  mixins: [storeWatchMixin],
  data(){
    return {
      video
    }
  },
  components: {
    PinedVideo,
    SectionKeyNumbers
  },
  mounted(){
    
    // console.log("mounted -> mounted", )

    this.initScene()
    this.onResize ()
    // this.transitionIn();
    
  },
  methods: {
    onResize (){
      
      var top = ''
      
      if(this.windowWidth >= 992)
        top = `calc(75vh - ${$("#annualheader").height()}px - 80px)`;
      else
        top = `calc(75vh - ${$("#annualheader").height()}px - 80px)`;

      if($("#annualheader").height() != 0)
        gsap.set('#annualheader', {top})
    },
    scrollToHome (){
      gsap.to (window, {scrollTo: 0, duration : 0.5, ease: 'power3.out', onComplete: () => {
        this.scrollMagicController.update();
      }});
    },
    initScene (){

        //annualheader visibility
        new ScrollScene({
                            triggerElement: "#triggerNavi",
                            duration: "20%",
                            triggerHook: 0.5,
                            gsap:{
                                timeline: gsap.timeline({ paused: true }).to("#annual-navigation",  {duration:1, autoAlpha: 1, ease:'power1.in'})
                            }
                        })
           
        //annualheader visibility
        new ScrollScene({
                            triggerElement: "#triggerHeaderText",
                            triggerHook: 0.5,
                            duration: "100%",
                            gsap: {
                                timeline: gsap.timeline({ paused: true })
                                    .to([".logo-mobile"],  {duration:1,  ease:'power1.in'})
                                    .to([".logo-mobile"],  {duration:1, opacity: 0, ease:'power1.in'})
                                    .from([".navi-pictet-logo-ico", '.navi-pictet-logo-ico-white'],  {duration:1, opacity: 0, ease:'power1.in'})
                            }
                        })
                        

        //annualheader visibility
        new ScrollScene({
                            triggerElement: "#triggerHeaderHide",
                            triggerHook: 0.7
                        })
                        
        .Scene.setClassToggle("#annualheader", "hidden")
        

    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
