<template>
    <div style="overflow: hidden">
        <div ref="pin">
            <slot>
                <!-- childs -->
            </slot>
        </div>
        <div ref="trigger" class="pin-trigger">
            <!-- this will trigger pin event -->
        </div>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'

import { ScrollScene } from 'scrollscene'
export default {
  name: 'pinned-section',
  mixins: [storeWatchMixin],
  props: ['route'],
  data(){
      return {
          scene: null,
          state: ''
      }
  },
  mounted(){
    this.initScene()
  },
  methods: {
    onResize (){
        if (this.scene){
            this.scene.duration (this.windowHeight);
            this.scene.update();
        }
    },
    initScene (){
        
        this.scene = new ScrollScene({
            triggerElement: this.$refs.trigger,
            triggerHook: 1,
            duration: this.windowHeight,
            // pushFollowers: true
        }).Scene

        this.scene.setPin(this.$refs.pin, {pushFollowers: false})
        


      var sceneEnter = new ScrollScene({
                              triggerElement: this.$refs.pin,
                              triggerHook: 1
                              
                            })
      
      sceneEnter.Scene.on("enter", () => { this.state = "enterTop"; this.isVisible = true; this.updateHash()})
      sceneEnter.Scene.on("leave", () => { this.state = "leaveTop"; this.isVisible = false})

      var sceneLeave = new ScrollScene({
                              triggerElement: this.$refs.trigger,
                              triggerHook: 1,
                          })

      sceneLeave.Scene.on("enter", ()=> { this.state = "leaveBottom"; this.isVisible = false})
      sceneLeave.Scene.on("leave", ()=> { this.state = "enterBottom"; this.isVisible = true; this.updateHash()})
    }
  }
}
</script>

<style lang="scss">
    
</style>