<template>
    <div ref="popup" class="popup-view popup-view-cookies u-enabled-interaction">
        <div class="container container-nopadding " style="height: 0; min-height: 0; position: absolute;">
                
            <div class="container-popup-close">
                <div class="popup-back-button u-enabled-interaction" @click="close">
                    
                    <img class="cookies-popup-bar-close" width="14" height="14" src="@/assets/popup-close.svg" alt="">

                </div>
            </div>
            
        </div>
        <div ref="popupScrollbar" class="popup-scrollbar">
            <div ref="popup-content" class="popup-content">
                
                <div class="container container-nopadding">
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-4">
                            <div class="popup-title">
                                Cookies policy
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-4">
                            <div>
                                <p class="header-text">
                                    <br>
                                    <br>
                                    We are particularly mindful of your expectations with regard to discretion and your right to privacy, and we therefore strive to retain the trust you show in us.
                                </p>
                                <p class="header-text">
                                    <br>
                                    <br>
                                    This cookie policy therefore aims to describe exactly how we handle the information gathered during visits to this website, and to offer you the opportunity to set cookie usage in accordance with your preferences.
                                </p>                                
                            </div>
                        </div>
                    </div>

                    <!-- 1 -->
                    <div class="row cookie-policy-paragraph-wrapper">
                        <div class="col-12 col-md-8 offset-md-4 col-lg-3 offset-lg-4">
                            <div class="cookie-policy-paragraph-title">
                                <div class="numeration">1</div>
                                What cookies do we use and how are they set?
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-0">
                            <div class="cookie-policy-paragraph">
                                <p>A cookie is a text file sent by a website and recorded on your IT terminal (e.g. computer or smartphone) when an online service is viewed from your browser. The vast majority of websites use cookies.</p>
                                <p>A cookie enables its issuer to identify the terminal on which it is recorded, during the period when the cookie is valid and being stored.</p>
                                <p>Any method of storage used by the browser, such as local storage, session storage or any other local browser database, is considered to be a “cookie” within the meaning of this definition.</p>
                            </div>
                        </div>
                    </div>

                    <!-- 2 -->
                    <div class="row cookie-policy-paragraph-wrapper">
                        <div class="col-12 col-md-8 offset-md-4 col-lg-3 offset-lg-4">
                            <div class="cookie-policy-paragraph-title">
                                <div class="numeration">2</div>
                                What do we use cookies for?
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-0">
                            <div class="cookie-policy-paragraph">
                                <p>
                                In general, cookies allow you to navigate our website more easily and efficiently. Subject to your agreement, which may be modified at any time, we use cookies on our website in order to enhance the way you navigate our site. In particular, cookies allow you to set your connection preferences (e.g. language) and to subscribe for products and service online.</p>

                                <p>The cookies we issue also enable us to collect statistical data and monitor traffic and usage volumes for the various components of our website. This allows us to make our services more attractive and user-friendly and adapt the way our website is presented to you.</p>
                            </div>
                        </div>
                    </div>

                    <!-- 3 -->
                    <div class="row cookie-policy-paragraph-wrapper">
                        <div class="col-12 col-md-8 offset-md-4 col-lg-3 offset-lg-4">
                            <div class="cookie-policy-paragraph-title">
                                <div class="numeration">3</div>
                                What cookies do we use and how are they set?
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-0">
                            <div class="cookie-policy-paragraph">
                                <p>In principle, cookies are recorded on a terminal only with the consent of the terminal user. There are several ways to manage cookies: the user may at any time choose to express or modify their cookie preferences via their browser settings, or, for our website, by means of the tools described below.</p>

                                <p>Each browser is configured differently as described in the browser’s help menu. This will tell you how to change your general preferences regarding cookies.</p>

                                <p>If you have accepted cookies in your web browser, with the result that cookies are recorded on your terminal, the cookies contained in the pages and content you have viewed may be stored temporarily in a specific area of your terminal.</p>

                                <p>If you decline to allow cookies to be recorded on your terminal, or if you delete those already recorded on it, you will no longer be able to benefit from a number of features on our website.</p>

                                <p>The cookies we use on our site can be set individually by you according to their type. More detailed information is given below regarding each type of cookie (data collected, purpose of the cookies and how long the data is stored) and you can choose to accept or decline their use.</p>



                                <p class="idented">a. Technical cookies</p>

                                <p>Technical cookies are vital to the functioning of the website. These cookies enable you to browse our site and make use of its features. Their settings therefore cannot be changed.</p>

                                <p>Cookies are stored for a maximum of 13 months.</p>




                                <p class="idented">b. Analytical cookies</p>

                                <p>These cookies gather information on how visitors use the website (e.g. number of visits, most popular pages, most-viewed content). They enable us to find out how our website is used, how it performs, and to collect statistics and monitor traffic volumes in order to make our services more attractive and user-friendly.</p>

                                <p>Cookies are stored for a maximum of 13 months.</p>

                                <p>These cookies are for internal use only.</p>


                            </div>
                        </div>
                    </div>

                    <!-- 4 -->
                    <div class="row cookie-policy-paragraph-wrapper">
                        <div class="col-12 col-md-8 offset-md-4 col-lg-3 offset-lg-4">
                            <div class="cookie-policy-paragraph-title">
                                <div class="numeration">4</div>
                                Renewing your consent
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-0">
                            <div class="cookie-policy-paragraph">
                                <p>
                                When you consent to the use of cookies, your consent is valid for a maximum of 13 months, after which you will be asked to renew it. A renewal request may also be triggered if you connect from a different terminal or modify your browser settings or when a new version of the website is released.
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- 5 -->
                    <div class="row cookie-policy-paragraph-wrapper">
                        <div class="col-12 col-md-8 offset-md-4 col-lg-3 offset-lg-4">
                            <div class="cookie-policy-paragraph-title">
                                Manage your consent
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-0">
                            <div class="cookie-policy-paragraph">
                                <p class="analitical">ANALITICAL COOKIES</p>
                                
                                <div class="checkbox">
                                    <input ref="checkbox" type="checkbox" id="checkbox_1" @click="toggleAnalitics($event)">
                                    <label for="checkbox_1"><p>These cookies gather information on how visitors use the website (e.g. number of visits, most popular pages, most-viewed content). Then enable us to find out how our website is used and how it performs, and to collect statistics and monitor traffic volumes in order to make our services more attracitve and user-friendlt. Cookies stored for a maxiumum of 13 months. These cookies are for internal use only. </p> </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>


<script>
import Scrollbar from 'smooth-scrollbar'
import storeWatchMixin from '@/mixins/storeWatchMixin'

export default {
    mixins: [storeWatchMixin],
    name: 'PopupCookiesPolicyView',
    data(){
        return{
            sectionName: "section-popup-cookies",
            tl: null,
        }
    },
    mounted(){
        
        if (this.$cookies.get('gaDisabled') !== 'true'){
            $(this.$refs.checkbox).prop('checked', true)
        }
        
        this.tl = new TimelineMax({paused: true})
            .from([this.$refs.popup], 0.85, {top: '100vh', ease: Power3.easeOut}, 'insync')
            .from(this.$refs['popup-content'], 0.85, {top: '-50vh', opacity: 0, ease: Power3.easeOut}, "insync")
            .from('.popup-back-button', 0.85, {top: '-100vh', ease: Power3.easeOut}, "insync")
            .to('.popup-overlay-apla', 0.65, {opacity: 1, ease: Power3.easeOut}, 'insync')

        this.setupScrollBar();

        window.eventBus.$on('open_popup_cookies_policy', () => {
            
            this.tl.timeScale(1)
            this.tl.play()
            this.$store.dispatch('setPopupVisbilityState', true);

        });

    },
    methods: {
        createCookie(name, value, days) {
            this.$cookies.set(name, value)
        },
        toggleAnalitics (e) {
            
            window['ga-disable-UA-163839023-1'] = !e.target.checked;
            this.createCookie('gaDisabled', !e.target.checked);

        },
        close(){
            
            this.tl.timeScale(1.5)
            this.tl.reverse()
            this.$store.dispatch('setPopupVisbilityState', false);

        },
        setupScrollBar (){

            this.scroller = Scrollbar.init(this.$refs.popupScrollbar);

        },
        onScrollBarChange (status){
            
            var colLeftHeight = $(this.$refs.colLeft.querySelector('.col-content')).outerHeight();
            var colRighttHeight = $(this.$refs.colRight.querySelector('.col-content')).outerHeight();
            
            var el = (colLeftHeight > colRighttHeight) ? this.$refs.colRight: this.$refs.colLeft;
            var elBigger = (colLeftHeight <= colRighttHeight) ? this.$refs.colRight: this.$refs.colLeft;
            var limit = $(elBigger.querySelector('.col-content')).height() - this.windowHeight;

            var height = $(el.querySelector('.col-content')).outerHeight();
            var max = limit;
            var progress =  status.offset.y / max;
            
            if (this.windowWidth > 992){
                if(height > this.windowHeight){
                    
                    if( -status.offset.y + height < this.windowHeight)
                        el.querySelector('.col-content').style.top =  (status.offset.y -(height - this.windowHeight)) + "px" 
                    else
                        el.querySelector('.col-content').style.top = 0 + "px" 
                    
                }else{
                    el.querySelector('.col-content').style.top = status.offset.y + "px";
                }
                document.querySelector('.popup-back-button').style.top = status.offset.y + "px" 
            }
            else
                el.querySelector('.col-content').style.top = 0 + "px" 

        }
    }
}
</script>
