
export const keynumbersAbout = {
  rows: [
            { value: "4694", className: 'keynumber-1', description: "group full time<br>equivalent employees", unit: "", currency: "" },
            { value: "576", className: 'keynumber-2', description: "assets under management<br>or custody<sup>1</sup> ", unit: "BN", currency: "CHF" },
        
            { value: "28", className: 'keynumber-3', description: "offices", unit: "", currency: "" },
            { value: "17", className: 'keynumber-4', description: "countries", unit: "", currency: "" },
        
            { value: "6.6%", className: 'keynumber-3', description: "employee<br>turnover rate<sup>2</sup>", unit: "", currency: "" },
            { value: "56", className: 'keynumber-5', description: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;equity owners<br>including partners<br>and former partners<sup>3</sup>", unit: "", currency: "" }
        
  ]
}


export const tablesDataSection = {
  cols: [ '2019', '2018'],
  rows: [
    {currency: 'chf', value_1: '2,629 m', value_2: '2,694 m', description: 'Operating income' },
    {currency: 'chf', value_1: '539 m', value_2: '596 m', description: 'Consolidated profit' },
    {currency: 'chf', value_1: '36.8 bn', value_2: '38.5 bn', description: 'Total assets' },
    {currency: 'chf', value_1: '3.13 bn', value_2: '2.95 bn', description: 'Total equity<sup>1</sup>' },
    {currency: '', value_1: '20.5%', value_2: '21.1%', description: 'Core tier 1 capital ratio<sup>2</sup>' },
    {currency: '', value_1: '20.5%', value_2: '21.1%', description: 'Total capital ratio<sup>2</sup>' },
    {currency: '', value_1: '156%', value_2: '164%', description: 'Liquidity coverage ratio <sup>3</sup>' },
    {currency: 'chf', value_1: '576 bn', value_2: '496 bn', description: 'Assets under management or custody' }
  ]
}
