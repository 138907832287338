<template>
    <div ref="container" class="terms-text-container">
      <div :class="{'terms-text': true, 'terms-expand-link': isExpandable}" @click="click">
        <div class="terms-text-flexbox">
          <div class="terms-text-lp-column">{{index}}</div>
          <div>
             <slot>
              <!-- text will be rendered here -->
            </slot>

            <span>{{(isExpandable) ? (isExpanded) ? '' : ' ...' : ''}}</span>
            
            <span ref="extendedContent" v-if="isExpanded" class="terms-more-text">
              <slot name="terms-expand-text">
                <!-- expand text will be rendered here -->
              </slot>
            </span>

          </div>
        </div>

      </div>
    </div>
</template>

<script>

export default {
  name: 'terms-text',
  props: ['index'],
  data() {
    return {
      isExpandable : false,
      isExpanded : false
    }
  },
  methods: {
    click() {
      if (!this.isExpanded) {
        gsap.to(this.$refs.extendedContent, {duration: 0.35, height: "auto", ease: "power4.out", onComplete:()=>{
          window.eventBus.$emit('udpate-scroll-magic')
        }})
      }else{
        gsap.to(this.$refs.extendedContent, {duration: 0.35, height: 0, ease: "power4.out", onComplete: ()=>{
          window.eventBus.$emit('udpate-scroll-magic')
        }})
      }

      this.isExpanded = !this.isExpanded;
      this.$emit('expand', this)
    }
  },
  mounted(){

    if (this.$slots['terms-expand-text'])
      if (this.$slots['terms-expand-text'][0])
        this.isExpandable = true;

    if (!this.$slots.default)    
      this.$refs.container.style.display = "none"

  }
}
</script>

<style lang="scss">

</style>