
import { mapGetters } from 'vuex'

var storeWatchMixin = {
    methods: {
        onResize (windowWidth, windowHeight){
            
            windowWidth, windowHeight

        },
        onEnter(){
            
            // console.log("TCL: onEnter ")
            
        },
        onLeave(){
            
            // console.log("TCL: onEnter ")
            
        },
        updateHash (){
            
            if (this.route && this.$store.getters.isAppMounted && this.$store.getters.updateURLonScroll){
                
                if (this.windowWidth >= 576)
                {    
                    var currentRoute = this.$router.currentRoute.name;
                    
                    if (currentRoute != this.route)
                        this.$router.push({ name: this.route})
                    
                }
                        
            }
        }
    },
    computed:{
        
        windowWidth(){
            return this.$store.getters.windowSize.width;
        },

        windowHeight(){
            return this.$store.getters.windowSize.height;
        },

        isMobile(){
            return (this.windowWidth <= 768) ? true : false;
        },
        
        ...mapGetters({
            isAppMounted: 'isAppMounted',
            setUpdateURLonScroll: 'setUpdateURLonScroll'
        })
    },
    watch: {
        windowWidth: function (){
            this.onResize(this.windowWidth, this.windowHeight);
        },
        windowHeight: function (){
            this.onResize(this.windowWidth, this.windowHeight);
        },
    }
}

export default storeWatchMixin;