<template>
    <div class="page-text  pictetgroup-pined-section">
        <slot name="background">
            <!-- Background component -->
        </slot>
        <div class="container container-nopadding" >            
            <div class="row">
                <div class="col-12 col-md-5 col-xl-4"></div>
                <div class="col-12 col-md-7">
                    <h3 class="page-header">
                        <slot name="header-title">
                            <!-- Header title -->
                        </slot>
                    </h3>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-md-4"></div>
                <div class="col-3 col-md-2 d-none d-sm-block d-md-none d-xl-block terms-text-wrapper">
                    <terms-text>
                        <slot name="terms-text">
                            <!-- Terms text -->
                        </slot>
                    </terms-text>
                    <terms :footnotes="footnotes"></terms>
                </div>

                <!-- <div class="col-12 col-md-2"></div>
                <div class="col-12 col-md-4"></div> -->

                <div class="col-12 col-sm-9 col-md-7 offset-md-1 col-xl-5">
                    <div class="table-container table-text">
                        <div class="table-row table-row-header">
                            <div class="table-col table-col--currency" style="width: 10%;"></div>
                            <div class="table-col table-col--center table-col-title" style="width: 20%;">
                                {{data.cols[0]}}
                            </div>
                            <div class="table-col table-col--center table-col-title" style="width: 20%;">
                                {{data.cols[1]}}
                            </div>
                            <div class="table-col " style="width: 50%;"></div>
                        </div>
                        <div v-for="(item, index) in data.rows" :key="index">
                            <div class="table-row">
                                <div class="table-col table-col--currency" style="width: 10%;">{{item.currency}}</div>
                                <div class="table-col table-col--center " style="width: 20%;">{{item.value_1}}</div>
                                <div class="table-col-separator " style="left: 27%"></div>
                                <div class="table-col table-col--center  " style="width: 20%;">{{item.value_2}}</div>
                                <div class="table-col-separator " style="left: 46%"></div>
                                <div class="table-col table-col-description " style="width: 50%;" v-html="item.description">{{}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <!-- <div class="col-12 col-md-5 col-xl-7 u-disabled-interaction"></div> -->
                <!-- <div class="col-12 col-sm-9 offset-sm-3 col-md-7 offset-md-0 col-xl-5">
                    <div class="table-container table-text">
                        <div class="table-row table-row-header">
                            <div class="table-col table-col--currency" style="width: 10%;"></div>
                            <div class="table-col table-col--center table-col-title" style="width: 20%;">
                                {{data.cols[0]}}
                            </div>
                            <div class="table-col table-col--center table-col-title" style="width: 20%;">
                                {{data.cols[1]}}
                            </div>
                            <div class="table-col " style="width: 50%;"></div>
                        </div>
                        <div v-for="(item, index) in data.rows" :key="index">
                            <div class="table-row">
                                <div class="table-col table-col--currency" style="width: 10%;">{{item.currency}}</div>
                                <div class="table-col table-col--center " style="width: 20%;">{{item.value_1}}</div>
                                <div class="table-col-separator " style="left: 27%"></div>
                                <div class="table-col table-col--center  " style="width: 20%;">{{item.value_2}}</div>
                                <div class="table-col-separator " style="left: 46%"></div>
                                <div class="table-col table-col-description " style="width: 50%;" v-html="item.description">{{}}</div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
            <div class="col-12 col-md-5 col-xl-4"></div>
            <div class="col-8 col-md-3 d-block d-sm-none d-md-block d-xl-none">
                    <terms-text>
                        <div class="terms-black">
                            Download Annual the Report 2019 (full financial report)
                            <div >
                                <span class="terms-link"><a href="https://static.group.pictet/sites/default/files/2020-04/AR2019-Financial-annual-report-EN.pdf" target="_blank">English <div class="terms-link-arrow">→</div></a></span>
                                <span class="terms-link"><a href="https://static.group.pictet/sites/default/files/2020-04/AR2019-Financial-annual-report-FR.pdf" target="_blank">French <div class="terms-link-arrow">→</div></a></span>
                            </div>
                        </div>
                    </terms-text>
                    <terms :footnotes="footnotes"></terms>
                </div>
            </div>
            <!-- <div class="row u-disabled-interaction" style="height: 100px;"></div> -->
            <section-bottom-fill></section-bottom-fill>
        </div>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import PinedSection from '@/components/pined-section-container'
import BackgroundContainer from '@/components/section-background-container'
import Keynumber from '@/views/key-number'
import TermsText from '@/views/terms-text'
import Terms from '@/views/terms'
import sectionBottomFill from '@/views/section-bottom-fill'

export default {
  name: 'section-table',
  mixins: [storeWatchMixin],
  components:{
    PinedSection,
    Keynumber,
    TermsText,
    Terms,
    BackgroundContainer,
    sectionBottomFill
  },
  data(){
      return {
            footnotes: [
                [`Total equity is the capital that the equity owners have entrusted to the Pictet Group`, `. It also corresponds to the net value of the Pictet Group from an accounting point of view. In Pictet’s case, equity, core tier 1 capital and total capital all amount to the same figure, since Pictet only holds the strongest form of capital. `],
                [`In Pictet’s case, the core tier 1 capital ratio and`, ` the total capital ratio are identical (see previous note, Total equity): namely the ratio of equity to risk-weighted assets. These measures aim to reflect the economic strength of a financial institution by taking into account the riskiness of its assets and its operations. `],
                [`The liquidity coverage ratio is the ratio of highly liquid`, ` assets to expected short-term liabilities. This measure aims to reflect the ability of a financial institution to withstand short-term liquidity disruptions such as sudden cash withdrawals from clients. The Pictet Group’s high ratio is explained by its large cash deposits with central banks and investments in highly liquid bonds.`]
            ]
      }
  },
  props:{
      data:{
          type: Object,
          required: true,
          default: () => {rows: []}
      }
  }
}
</script>

<style lang="scss">

</style>