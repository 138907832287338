<template>
<div>
       
    <div ref="container" >
        
        <div ref="triggerStart">
          <!-- trigger: cover transition in -->
        </div>


        <portal to="cover-portal">
          
          <div style="position: absolute; width: 100%; height: 100%;">
            <div  class="container container-cover">
              <div ref="header-row" class="row page-title-container align-items-center align-items-md-end justify-content-start justify-content-md-end">
                    
                <h1 :id="'header-' + name " class="cover-header">
                  {{text}}
                </h1>

              </div>
            </div>  
          </div>

        </portal>


        <div class="u-enabled-interaction">
          <slot>
            <!-- childrens -->
          </slot>
        </div>
        
        <div ref="triggerEnd">
          <!-- trigger: cover transition out -->
        </div>

    </div>
  </div>
    

</template>

<script>
import { ScrollScene } from 'scrollscene'
import storeWatchMixin from '@/mixins/storeWatchMixin'
export default {
    name: 'section-cover',
    props: ['text', "name", "route"],
    mixins: [storeWatchMixin],

    mounted(){
      
      this.$nextTick(() => {
        this.initScene(); 
      })      

    },
    computed: {
      id(){
        return '#header-' + this.name
      }
    },
    methods: {
      initScene (){

        var headerTransitionIn = gsap.timeline({paused:true})
            .fromTo(this.id, {duration:1, opacity: 0}, {opacity: 1, ease: 'power1.in'})

        new ScrollScene({
                          triggerElement: this.$refs.triggerStart,
                          duration: 500,
                          triggerHook:0.75,
                          gsap: {
                            timeline: headerTransitionIn
                          }
                      })
      .Scene.on("enter", () => { this.state = "enterTop"; this.isVisible = true; this.updateHash()})
      .on("leave", () => { this.state = "leaveTop"; this.isVisible = false})

      
        var headerTransitionOut = gsap.timeline({ paused: true })
            .to(this.id, {duration:0.3,   opacity: 0, ease:'power1.in'})
      
        new ScrollScene({
                        triggerElement: this.$refs.triggerEnd,
                        duration: 1,
                        triggerHook: 1,
                        gsap: {
                            timeline: headerTransitionOut
                        }
                    })
      .Scene.on("enter", ()=> { this.state = "leaveBottom"; this.isVisible = false})
      .on("leave", ()=> { this.state = "enterBottom"; this.isVisible = true; this.updateHash()})

      }
    }
}
</script>

<style lang="scss">
    
</style>