<template>
  <div id="app">
    


    <div style="position: relative; z-index: 2;">
      <introduction id="home"></introduction>
      <pictetgroup id="pictet-group"></pictetgroup>
      <ambition id="ambition"></ambition>
      <business id="business"></business>
      <acting-responsibly id="acting-responsibly"></acting-responsibly>
      <get-the-annual-review id="get-the-annual-review"></get-the-annual-review>
    </div>
    


    <div style="position: relative; z-index: 0;">
      <video-portal></video-portal>
    </div>




    <div class="cover u-disabled-interaction u-none-selectable" style="z-index: 1;">  
      <portal-target name="cover-portal" multiple >
      
      </portal-target>
    </div>
    


    <div id="pictet-logo-container" class="conatiner-fluid fixedTop u-disabled-interaction" style="z-index: 1003;"> 

      <div class="container">
        <div class="row">
          <div class="col">
            <img class="logo u-enabled-interaction" src="@/assets/images/logo.svg" @click="scrollToHome">
            <img class="logo-mobile u-enabled-interaction" src="@/assets/images/logo.svg"  @click="scrollToHome">
            <img class="navi-pictet-logo-ico-white u-enabled-interaction " src="@/assets/images/lion-ico-white.svg"  @click="scrollToHome" alt="" srcset="">
            <img class="navi-pictet-logo-ico-tablet u-enabled-interaction" src="@/assets/images/logo.svg" @click="scrollToHome">
          </div>
        </div>
      </div>
    </div>

  


    <page-scroll-cover></page-scroll-cover>


    
    <navigation></navigation>

    <policy-bar></policy-bar>

    <policy-popup></policy-popup>




  </div>
</template>
<script>
import introduction from '@/sections/introduction'
import pictetgroup from '@/sections/pictetgroup'
import ambition from '@/sections/ambition'
import business from '@/sections/business'
import actingResponsibly from '@/sections/acting-responsibly'
import getTheAnnualReview from '@/sections/get-the-annual-review'
import navigation from '@/components/navigation'
import videoPortal from '@/components/video-portal'
import pageScrollCover from '@/views/page-scroll-cover'
import policyBar from '@/views/policy-bar'
import policyPopup from '@/views/policy-popup'
import { ScrollScene } from 'scrollscene'
import { mapGetters } from 'vuex'

export default {
  components: {
    videoPortal,
    introduction,
    pictetgroup,
    ambition,
    business,
    actingResponsibly,
    getTheAnnualReview,
    pageScrollCover,
    navigation,
    policyBar,
    policyPopup
  },
  data(){
    return {
      scrollMagicController: null
    }
  },
  mounted(){
    
    $(window).on('resize', this.onresize);

    this.$nextTick(() => {
      this.onresize();
      if(this.scrollMagicController)
        this.scrollMagicController.update ();
    });

    window.eventBus.$on('udpate-scroll-magic', () => this.$nextTick(() => {
      this.$nextTick(() => {
        this.scrollMagicController && this.scrollMagicController.update () 
      })
    }));

    this.init();
    this.onresize();

    
    this.$nextTick(() => {
    
      var to = this.$router.currentRoute;
      var matchedId = 0//to.matched.length - 1
      var id = to.matched[matchedId].props.default.id;
      var offset = to.matched[matchedId].props.default.offset;

      if (offset == 800)
        offset = this.windowHeight
      
      var scrollTo = $('#' + id).offset().top + offset
      // console.log("mounted -> setTimeout", scrollTo, to, id)
      gsap.to (window, {scrollTo, duration: 0.15, onComplete: () => {
        this.transitionIn();
        setTimeout(() => {
          this.$store.dispatch('setAppMounted', true);
          this.$store.dispatch('setUpdateURLonScroll', true);
        }, 500);

      }}
      );
    })
    
    
    
  },
  methods: {
    init () {
      var scene = new ScrollScene({
                    triggerElement: '#app',
                    duration: '100%',
                    triggerHook: 1,
                  });
      
      this.scrollMagicController = scene.Scene.controller ();
      this.scrollMagicController.update ();
      
    },

    transitionIn(){

      var bgRect = document.getElementById('intro-bg').getBoundingClientRect()
      var intro = true;
      var tl = gsap.timeline({clearProps: true, delay: 0.05})
      var isDesktop = window.innerWidth >= 768
      
      if (bgRect.y + window.innerHeight < 0)
        intro = false

        

        // console.log("transitionIn -> else", intro)
        
      if (intro) {
        
        tl
          .to('.preloader-background', { duration: 0.5,   opacity: 0, ease: "power1.in"}, "start")

          .from('.intro-bg', { duration: 1, height: window.innerHeight + bgRect.y * -1, clearProps: true, ease: "power1.out"}, "sync+=0.5")
          .to('#pictet-logo-container', { duration: .5, opacity: 1, ease: "power1.out"}, "sync+=0.75")

          .from('#annualheader', { duration: .5, opacity: (isDesktop) ? 0 : 0, delay: (isDesktop) ? 0 : 0.15, ease: "power3.out"}, "sync+=0.75")
          .to('.preloader-container svg', { duration: 0.5, opacity: 0, ease: "power3.inOut"}, (window.innerWidth >= 768) ?  "sync": "start")
        
      }else{
        
        tl
          .to('.preloader-background', { duration: 0.75,  height: 0, ease: "power3.inOut"}, "sync")
          .to('#pictet-logo-container', { duration: .25, opacity: 1, ease: "power3.out"}, "sync+=0.55")
          .to('.preloader-container svg', { duration: .35, opacity: 0, ease: "power3.in"}, "sync+=0")

      }

      tl.call (() => {
        $('.preloader-container').detach()
      })
        
    },
    onresize () {
      
      this.iwidth = $(window).outerWidth();
      this.iheight = document.body.clientHeight || $(window).outerHeight();
      
      this.dispatchToStoreNewSize ();
      this.scrollMagicController.update ();

      this.$nextTick(() => {
      this.dispatchToStoreNewSize ();
        this.scrollMagicController.update ();
      })
      
      
    },
    dispatchToStoreNewSize () {
      
      var windowSize = {
          width: this.iwidth,
          height: this.iheight
        }

      this.$store.dispatch('setWindowSize', windowSize);
      
    },
    scrollToHome () {
      
      window.eventBus.$emit('SCROLL_TO_HOME');

    },
    toggleBodyScroling(value){
      if(this.isPopOpened)
        $('body').css("overflow", 'hidden')
      else{
        $('body').css("overflow", 'auto')
        $('body').css("overflow-x", 'hidden')
      }

    }
  },

  computed:{

    ...mapGetters({
      isPopOpened: 'isPopOpened'
    }),

  },
  watch: {

    isPopOpened: function (value){
      
      this.toggleBodyScroling()

    },
  }
}
</script>
<style lang="scss">

@import "scss/main";

</style>
