import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import gsap from 'gsap';
import * as ScrollMagic from "scrollmagic"; 
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import PortalVue from 'portal-vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

Vue.$cookies.config('30d')

Vue.use(PortalVue)
// Vue.prototype.$scrollmagic = new ScrollMagic.Controller();

gsap.install(window);
gsap.config({nullTargetWarn:false});

ScrollMagicPluginGsap(ScrollMagic, gsap);

window.eventBus = new Vue();

Vue.config.productionTip = true

setTimeout(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}, 350)
