<template>
    <div ref="wrapper">
        <terms-text ref="container" @expand="onExpand" v-for="(item, index) in footnotes" :index="index + 1" :key="index">
            <span class="key-numbers-terms-item">
                
                <span v-html="item[0]"></span>
                
            </span>
            
            <span v-if="item.length > 1" slot="terms-expand-text" v-html="item[1]">

            </span>
        
        </terms-text>
    </div>
</template>

<script>
import TermsText from '@/views/terms-text'
export default {
    props: ['footnotes'],
    components:{
        TermsText,
    },
    data(){
        return {
            wrapperHeight : 0
        }
    },
    methods: {
        onExpand (e, t) {
            
            for (let i = 0; i < this.$refs.container.length; i++) {
                const container = this.$refs.container[i];
                
                if (container != e)
                    container._data.isExpanded = false;
            }
            
            this.$nextTick(()=>{
                if (this.wrapperHeight < $(this.$refs.wrapper).outerHeight())
                    this.wrapperHeight = $(this.$refs.wrapper).outerHeight()
                
                this.$refs.wrapper.style.height = this.wrapperHeight + "px";
            })
            
        }
    },
}
</script>

<style lang="">
    
</style>