<template>
  <div :class="['key-number-container', className]">
    <div class="key-number">
      <span v-if="currency != ''" class="key-number-currency" v-html="currency">
        
      </span>
      <span class="key-number-value" v-html="value">
        
      </span>
      <span v-if="unit != ''" class="key-number-unit" v-html="unit">
        
      </span>
    </div>
    <div class="key-number-description" v-html="description"></div>
    <div class="key-number-description-second" v-html="descriptionSecond"></div>
  </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'

export default {
  name: 'key-number-item',
  mixins: [storeWatchMixin],
  props: {
      className:{
        type: String,
        default: ''
      },
      value:{
        type: String,
        default: ''
      },
      description:{
        type: String,
        default: ''
      },
      descriptionSecond:{
        type: String,
        default: ''
      },
      currency:{
        type: String,
        default: ''
      },
      unit:{
        type: String,
        default: ''
      }
  }
}
</script>

<style lang="scss">

</style>