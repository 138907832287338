<template>
  <div style="overflow: hidden;">
    <div ref="container" class="section-offscreen">
        <slot></slot>
    </div>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import { ScrollScene } from 'scrollscene'
import gsap from 'gsap'
export default {
    name: 'section-offscreen-container',
    mixins: [storeWatchMixin],
    props: {
      switchHamburgerIconToBlack: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        scene: null
      }
    },
    methods: {
      onResize(){
        if (this.scene){
          this.scene.duration(this.windowHeight);
          this.scene.update();
        }
      }
    },
    mounted(){
      var timeline = gsap.timeline({ paused: true })
                .fromTo(this.$refs.container,  {x: '100%'}, {duration: 2, x: '0%', ease: 'power4.out'}, 'sync')
      
      this.scene = new ScrollScene({
          triggerElement: this.$refs.container,
          duration: this.windowHeight,
          triggerHook: "onLeave",
          gsap: {
            timeline: timeline
          }
        }).Scene;

      this.scene.setPin(this.$refs.container);
    }
}
</script>

<style lang="scss">
    
</style>