<template>
    <div >
        <div ref="background" :class="['background', backgroundColorClass]"></div>
        <div ref="container" class="container container-background">
            <div class="row">
                <div class="col-12 col-md-4 col-xl-3"></div>
                <div ref="rightColumn" class="col-12 col-md-8 col-xl-9">
                    <div class="right-background"></div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import { ScrollScene } from 'scrollscene'
export default {
    name: 'section-background-container',
    mixins: [storeWatchMixin],
    props:{
        backgroundColorClass: {
            type: String,
            default: 'white-bg'
        }
    },
    methods: {

        onResize (){
            this.$nextTick (() => {
                if (this.windowWidth >= 768){
                    var rightColumnWidth    = $(this.$refs.rightColumn).outerWidth ();
                    var containerWidth      = $(this.$refs.container).outerWidth ();
                    var width               = `calc((100% - ${containerWidth}px) / 2 + ${rightColumnWidth}px)`
                    
                    $(this.$refs.background).css({ 'width': width});
                }else{
                    $(this.$refs.background).css({ 'width': '100%'});
                }
            })
        },

    }
}
</script>

<style scoped lang="scss">
.container-background{
    padding-top: 0;
}
</style>

