<template>
  <PinedVideo :path="video.src" name="pictet-group" >
    <SectionCover text="The Pictet Group"  name="pictet-group" id="page0" route="pictet-group">
        
      
    <div class="space-for-video "></div>



      <!-- About -->





      <PinedSection id="page1" route="about-us">
        <OffscreenTransitionContainer :switchHamburgerIconToBlack="true">
          <SectionContainer  class="pictetgroup-pined-section" id="page1-content"  name="pictet-group">
            
              <div slot="title">
                About the Pictet Group
              </div>
              
              <div slot="background">
                <BackgroundContainer></BackgroundContainer>
              </div>

              <div slot="column1">
                <div class="black-text small-body no-indent">
                  The Pictet Group comprises four business units — asset management, wealth management, alternative advisors (with effect from 1 January 2020) and asset services — and is constituted as a <i>société en commandite par actions</i> (a corporate partnership), which is the managing entity of the Group. 
                </div>
                <div class="black-text small-body">
                  The principal operating companies are the Swiss bank, Banque Pictet & Cie SA; Pictet & Cie (Europe) SA; Bank Pictet & Cie (Asia) Ltd; and the asset management subsidiaries grouped under Pictet Asset Management Holding SA, a holding company belonging to the Pictet Group. 
                </div>
                <div class="black-text small-body">
                  The Group is owned and managed by seven managing partners, with an independent supervisory board. Renaud de Planta, a partner since 1998, became senior partner on 1 September 2019. <div class="black-text small-body no-block">Sébastien Eisinger was elected a partner of the Pictet Group with effect from 1 April 2019. He became the forty-third partner since Pictet was founded in Geneva in 1805.</div> 
                </div>
              </div>
              
              <div slot="column2">
                <div class="black-text small-body">
                  At 1 April 2020, a further 44 senior executives, known as equity partners, held equity in the Group. They each lead a strategically important function and play a central role in the development of Pictet. Elections are made every second year. 

                </div>
                <div class="black-text small-body">
                  In selecting new employees and promoting career advancement, Pictet aims for diversity of gender, background and education, which together contribute to diversity in thought, opinion and experience. But diversity also goes hand in hand with inclusion — that is, making each employee feel valued and giving each a voice in daily business.

                </div>
              </div>

              <div slot="terms-text-list">
                <div class="footnotes"  >
                  
                  <div class="terms-text terms-black terms-noline">
                    <span @click="goToAnnualReview" class="terms-link  terms-black">Order or download the full <span class="">Annual&nbsp;Review&nbsp;2019</span> 
                    <!-- <span class="terms-link-arrow terms-black">→</span> -->
                    </span>
                  </div>
                  <terms :footnotes="footnotesTop"></terms>
                </div>
                
                
              </div>

              <div slot="gallery">

                <div class="row">
                  <div class="col-12 col-md-4 col-xl-3"></div><div class="col-12 col-md-8 col-xl-9 whitespace"></div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-5 col-xl-4"></div>
                    <div class="col-12 col-md-7 col-lg-6 col-xl-8" ><img src="@/assets/images/partners.jpg" class="partners-image"></div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-5 col-xl-4"></div>
                    <div class="col-4 col-md-2"><p class="captions">The Managing Partners of the Pictet Group</p></div>
                    <div class="col-8 col-md-5 col-lg-4"><p class="captions">From left to right: Rémy Best, Laurent Ramsey, Bertrand Demole, Renaud de Planta (Senior Partner), Marc Pictet, Sébastien Eisinger, Boris Collardi</p></div>
                </div>

              </div>
              <div slot="tables">


                <div class="key-number-text--red">

                  <!-- <div class="row">
                    <div class="col-12 col-md-3"></div>
                    <div class="col-12 col-md-9 whitespace"></div>
                  </div> -->
                  <SectionKeyNumbers :data="keynumbersAbout" :footnotes="footnotes">
                    <div slot="header-title">
                      Key Figures
                    </div>
                  </SectionKeyNumbers>
                </div>
              </div>
          </SectionContainer>
          
        </OffscreenTransitionContainer>
      </PinedSection>












      <!-- Financial summary -->









      <PinedSection id="page2" route="financial-summary">

        <div class="pictetgroup-pined-section key-number-text--red">
          
          <SectionTable :data="tablesDataSection">
            <div slot="background">
              <BackgroundContainer backgroundColorClass="offwhite-bg"></BackgroundContainer>
            </div>
            <div slot="header-title">
              Financial summary
            </div>
            <div slot="terms-text" class="terms-black">Download <span class="terms-link-nowrap">the Annual</span> Report 2019 (full financial report)
              <div>
                <span class="terms-link"><a href="https://static.group.pictet/sites/default/files/2020-04/AR2019-Financial-annual-report-EN.pdf" target="_blank">English <div class="terms-link-arrow">→</div></a></span>
                <span class="terms-link"><a href="https://static.group.pictet/sites/default/files/2020-04/AR2019-Financial-annual-report-FR.pdf" target="_blank">French <div class="terms-link-arrow">→</div></a></span>
                <!-- <span class="terms-link terms-black">English <div class="terms-link-arrow terms-black">→</div></span>
                <span class="terms-link terms-black">French <div class="terms-link-arrow terms-black">→</div></span> -->
              </div>
            </div>
          </SectionTable>

        </div>
      </PinedSection>








      <!-- The history of the Pictet Group -->








      <PinedSection  id="page3" route="our-history">
        <SectionContainer class="pictetgroup-pined-section" id="page3-content">
          
            <div slot="title">
              <!-- The history <span class="italic">of</span><br>the Pictet Group -->
              The origins <i>of</i> <br>the Pictet Group
            </div>
            <div slot="background">
              <BackgroundContainer></BackgroundContainer>
            </div>
            <div slot="column1">
              <div class="small-body black-text no-indent">
                In 1798 the city state of Geneva is annexed by the French Directoire and becomes the capital of the Département du Léman. After the Napoleonic Wars end in 1815, Geneva joins the Swiss Confederation — in large part owing to the diplomatic efforts of the European statesman Charles Pictet de Rochemont. 

              </div>
              <p class="small-body black-text">
                War and blockade have interrupted watch exports — Geneva’s forte — while the French monarchy’s default after the 1789 Revolution has caused the collapse of most existing banks. <div class="black-text small-body no-block">Yet Geneva’s entrepreneurial flame, kindled by Calvinist habits of discipline and hard work, and fanned by the optimism of the Enlightenment, still burns bright. </div><div class="black-text small-body no-block">As the post-Revolutionary inflation subsides, a new generation of financial partnerships emerges, later to be known as private bankers.</div>

              </div>
              <div class="small-body black-text">
                The formal history of Pictet begins in Geneva on 23 July 1805. On that day, Jacob-Michel-François de Candolle and Jacques-Henry Mallet sign, with three limited partners, the <i>scripte de société</i> that creates the original partnership of de Candolle, Mallet & Cie. 

              </div>
            </div>
            <div slot="column2">
              <div class="small-body black-text">
                Beginning with share capital of 125,000 Geneva pounds (about 30 million Swiss francs in today’s money), Pictet’s founders describe their purpose as, ‘to trade in goods and articles of all types, collect annuities and undertake speculation in commodities’. Before long the bank comes to specialise in currency trading and the management of wealth. 

              </div>
              <div class="small-body black-text">
              Surviving account books and documents show that as early as the 1830s the bank held a broad range of securities on behalf of clients to ensure that risks were properly diversified. 
  
              </div>
              <div class="small-body black-text">
                On the death of de Candolle in 1841, his wife’s nephew Edouard Pictet joins the partnership. He becomes sole proprietor in 1848 and remains at the head of the bank until his retirement in 1878.

              </div>
            </div>
          
        </SectionContainer>
      </PinedSection>

    </SectionCover>

    <div class="space-for-video half half--mobile">
      <div ref="trigger" class="spacer"></div>
    </div>

  </PinedVideo>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import SectionContainer from '@/components/section-container'
import SectionCover from '@/components/section-cover'
import SectionKeyNumbers from '@/components/section-key-numbers'
import SectionTable from '@/components/section-table'
import PinedVideo from '@/components/pinned-video-container'
import BackgroundContainer from '@/components/section-background-container'
import { ScrollScene } from 'scrollscene'
import gsap from 'gsap'
import PinedSection from '@/components/pined-section-container'
import {keynumbersAbout, tablesDataSection} from '@/json/pictetgroup'
import OffscreenTransitionContainer from '@/components/section-offscreen-container'
import TermsText from '@/views/terms-text'
import Terms from '@/views/terms'

var video = {id: "video2", src: 'pictet-group.mp4'}

export default {
  name: 'pictetgroup',
  mixins: [storeWatchMixin],
  components: {
    PinedVideo,
    PinedSection,
    SectionContainer,
    SectionCover,
    SectionKeyNumbers,
    SectionTable,
    BackgroundContainer,
    OffscreenTransitionContainer,

    TermsText,
    Terms
  },
  data(){
    return {
      video,
      tablesDataSection: tablesDataSection,
      keynumbersAbout: keynumbersAbout,
      footnotesTop: [
        ['In this document the terms ‘Pictet Group’ or ‘the Group’ or ‘Pictet’ denote all entities in which the partners collectively have a direct or indirect majority stake.']
      ],

      footnotes: [

              ['The term ‘Assets under management or custody’ excludes double counting', '. It represents the assets of private and institutional clients looked after by the Pictet Group. These assets may be managed through individual discretionary mandates, benefit from value-added services such as investment advice, or simply be under deposit. '],
              ['Leavers as a percentage of average employee count'],
              ['At 1 April 2020']

            ]
    }
  },
  methods: {
    goToAnnualReview (){
      
      window.eventBus.$emit('SCROLL_TO_GET_ANNUAL_REVIEW');
      
    }
  },
  mounted (){
    
    var pictetgroupSlideOutTimeline = gsap.timeline({paused: true})
                            .to('#page3-content', {duration : 1, x: '100vw', ease: "power1.in"}, 'sync')
                            .to("#pictet-page-nav",  {duration: 1, height: 30, ease:'power3.inout'}, 'sync')
                            // .to(
                            //   '#page2'
                            //   , {duration : 0.001, x: '100vw', ease: "power1.in"}, 0)
                            // .to(
                            //   '#page3'
                            //   , {duration : 1, x: '100vw', ease: "power1.in"}, 0) 

     new ScrollScene({
                      triggerElement: this.$refs.trigger,
                      duration: '100%',
                      triggerHook: 1,
                      gsap:{
                        timeline: pictetgroupSlideOutTimeline
                      }
                  })
                  .Scene.setClassToggle("#page2", "u-v-hidden");

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
