<template>
    <div ref="policy-bar" class="policy-bar">
        <div class="cookies-policy-bar-title">
            <div>Cookies policy</div>
            <img class="cookies-policy-bar-close" @click="closeBar" src="@/assets/cookies-close.svg" alt="">
        </div>
        <div>
            <p class="cookies-policy-bar-paragraph">
                This website uses cookies to enhance user navigation and to collect statistical data. To refuse the use of cookies, change your settings or for more information, please click on the following link: <u @click="showCookiePolicy">Cookies policy</u>. By continuing to browse this website, you accept the use of cookies for the above purposes.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "policy-bar",
    mounted() {
        if(this.$cookies.get('cookiesPolicy') == "true")
            this.closeBar();
    },
    methods:{
        closeBar () {
            
            $(this.$refs['policy-bar']).detach ();
            this.$cookies.set('cookiesPolicy', 'true')
            
        },
        showCookiePolicy () {
            
            window.eventBus.$emit('open_popup_cookies_policy');

        }
    },
}
</script>

<style lang="scss">

</style>