<template>
    <div class="page-scroll-cover-mask dark-bg">
        <div class="page-scroll-cover ">
            <div class="container container-page-scroll-cover">
                <div ref="annual-title-container" class="row annual-title-container">
                    <div class="col-12 col-md-5" >
                        <h1 ref="annual-title"  class="pictet-group-header cover-header">
                            <p>The Pictet Group</p>
                            <p>Annual Review</p>
                            <!-- <p class="annual-date-header">31 December 2019</p> -->
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
export default {
    mixins: [storeWatchMixin],
    mounted(){
        
        window.eventBus.$on('on-show-transition', this.showTransition);
        this.onResize();
        
    },
    methods: {
        showTransition (e){
            
            var duration = .35,
                ease = 'power4.out',
                easeout = 'power4.in'
            

            var bgRect = document.getElementById('intro-bg').getBoundingClientRect()
            var intro = true
            
            if (bgRect.y + window.innerHeight < 0)
                intro = false
            
            
            var tl = gsap.timeline ()
                .to ('.page-scroll-cover-mask', {duration, ease, y: "-100vh"}, 'start')
                .to ('.page-scroll-cover', {duration, ease, y: "0vh"}, 'start')

                .to ('.page-scroll-cover-mask', {duration, ease: easeout, y: "-200vh"}, 'end')
                .to ('.page-scroll-cover', {duration, ease: easeout, y: "100vh"}, 'end')

                .set ('.page-scroll-cover-mask', {duration, ease: easeout, y: "0vh"})
                .set ('.page-scroll-cover', {duration, ease: easeout, y: "-100vh"})
            

            if (e == 1){
                tl.set(this.$refs['annual-title'], { opacity : 0}, "start")
                tl.from('.intro-bg', { duration, ease: "power3.inOut", height: "100vh"}, "end")
                tl.from('#annualheader', { duration: 1, ease: ease, opacity: 0}, "end+=" + duration)
                tl.set(this.$refs['annual-title'], { opacity : 1})

            }else{
                


            }

        },
        onResize (){
        
            var top = `calc(75vh - ${$(this.$refs['annual-title-container']).height()}px - 80px)`;
            
            if($(this.$refs['annual-title-container']).height() != 0)
                gsap.set(this.$refs['annual-title'], {top})

        },
    }
}
</script>

<style scoped lang="scss">

    .page-scroll-cover-mask, .page-scroll-cover{
        
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 971;
    }
    
    .page-scroll-cover-mask{
        position: fixed;
        overflow: hidden;
        top: 100vh;
        
    }
    
    .page-scroll-cover{
        position: absolute;
        
        transform: translateY(-100vh);
    }

    .annual-title-container{
        position: relative;
    }

    .cover-header{
        position: relative;
    }
    
    .container-page-scroll-cover{
        min-height: 100vh;
    }

</style>