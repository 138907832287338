import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

var routeMap =  {
  
  'introduction'            : {id: 'introduction', offset: 0},
  

  'pictet-group'            : {id: 'page0', offset: 0},
  'about-us'                : {id: 'page1', offset: 800},
  'financial-summary'       : {id: 'page2', offset: 0},
  'our-history'             : {id: 'page3', offset: 0},


  'ambition'                : {id: 'page4', offset: 0},


  'business'                : {id: 'business-page-start', offset: 0},
  'wealth-management'       : {id: 'business-page-id-1', offset: 800},
  'asset-management'        : {id: 'business-page-id-2', offset: 0},
  'alternative-advisors'    : {id: 'business-page-id-3', offset: 0},
  'asset-services'          : {id: 'business-page-id-4', offset: 0},


  'acting-sustainably'      : {id: 'acting-responsibly-page-start', offset: 0},
  

  'get-the-annual-reviews'  : {id: 'get-review-page-start', offset: 0},
  
}

const routes = [
  // {
  //   path: '',
  //   name: '',
  //   props:{
  //     id: '',
  //     offset: 0
  //   },
  // children: []
  // },
  
  {
    path: '/get-the-annual-reviews',
    name: 'get-the-annual-reviews',
    props:{
      id: 'get-review-page-start',
      offset: 0
    }
  },
  {
    path: '/acting-sustainably',
    name: 'acting-sustainably',
    props:{
      id: 'acting-responsibly-page-start',
      offset: 0
    }
  },
  {
    path: '/business',
    name: 'business',
    props:{
      id: 'business-page-start',
      offset: 0
    },
    children: [
      {
        path: 'wealth-management',
        name: 'wealth-management',
        props:{
          id: 'business-page-id-1',
          offset: 800
        },
      },
      {
        path: 'asset-management',
        name: 'asset-management',
        props:{
          id: 'business-page-id-2',
          offset: 800
        },
      },
      {
        path: 'alternative-advisors',
        name: 'alternative-advisors',
        props:{
          id: 'business-page-id-3',
          offset: 800
        },
      },
      {
        path: 'asset-services',
        name: 'asset-services',
        props:{
          id: 'business-page-id-4',
          offset: 800
        },
      }
    
    ]
  },
  {
    path: '/ambition',
    name: 'ambition',
    props:{
      id: 'page4',
      offset: 0
    }
  },
  {
    path: '/pictet-group',
    name: 'pictet-group',
    props:{
      id: 'page0',
      offset: 0
    },
    children: [
      {
        path: 'about-us',
        name: 'about-us',
        props:{
          id: 'page1',
          offset: 800
        },
      },
      {
        path: 'financial-summary',
        name: 'financial-summary',
        props:{
          id: 'page2',
          offset: 800
        },
      },
      {
        path: 'our-history',
        name: 'our-history',
        props:{
          id: 'page3',
          offset: 800
        },
      }
    ]
  },
  {
    path: '/introduction',
    name: 'introduction',
    props:{
      id: 'home',
      offset: 0
    }
  },
  {
    path: '/',
    name: 'home',
    props:{
      id: 'home',
      offset: 0
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   var matchedId = to.matched.length - 1
  //   var id = to.matched[matchedId].props.default.id;
  //   var offset = to.matched[matchedId].props.default.offset;
    
  //   var scrollTo = $('#' + id).offset().top + offset
    
  //   return {x: 0, y: scrollTo}
  // }
})

// router.beforeEach((to, from, next) => {
  // console.log("to", to, from)
  // next()
  // if( router.app.$store.getters.isAppMounted == false){
  //   console.log("before to", to.matched[0].props.default.id);
  //   console.log("$('#'+to.matched[0].props.default.id).offset()", $('#'+to.matched[0].props.default.id))
  //   gsap.set (window, {scrollTo: $('#'+to.matched[0].props.default.id).offset().top, duration : duration, ease: 'power1.inout'});
    
  // }
  // else
  //   next()

// })



function onLocation (){
      
  var id = ''
  var offset = ''
  Object.keys(this.routeMap).forEach(key => {
    if ('#/' + key == location.hash){
      id = this.routeMap[key].id
      offset = this.routeMap[key].offset
    }
  });
  this.gotoSection(0, id)
  // console.log("onLocation -> id", id, offset)
}

export default router
