<template>
    <div class="section-keynumbers">
        <div class="row">
            <div class="col-12 col-md-5 col-xl-4"></div>
            <div class="col-12 col-md-7">
                <h3 class="page-header">
                    <slot name="header-title">
                        <!-- Header title -->
                    </slot>
                </h3>
            </div>
        </div>
        <div class="row" >
            <div class="col-12 col-md-4"></div>
            <div class="col-3 col-md-2 d-none d-sm-block d-md-none d-xl-block terms-text-wrapper">
                <terms-text>
                    <slot name="terms-text">
                        <!-- Terms text on desktop -->
                    </slot>
                </terms-text>
                <terms :footnotes="footnotes"></terms>
            </div>
            <!-- <div class="col-12 col-md-2"></div>
            <div class="col-12 col-md-4"></div> -->

            <!-- <div class="row"> -->
            <!-- <div class="col-12 col-md-5 col-xl-7 u-disabled-interaction"></div> -->
            <div class="col-12 col-sm-9 offset-sm-0 col-md-6 offset-md-1 col-xl-5">
                <div class="row key-number-row" >
                    <div class="col-6 col-md-6 col-xl-6" v-for="(item, index) in data.rows" :key="index" >
                        <Keynumber 
                            :className="item.className" 
                            :value="item.value" 
                            :description="item.description" 
                            :descriptionSecond="item.descriptionSecond" 
                            :unit="item.unit" 
                            :currency="item.currency">
                        </Keynumber>
                    </div>
                </div>
            </div>
        <!-- </div> -->

        </div>
        <!-- <div class="row">
            <div class="col-12 col-md-5 col-xl-7 u-disabled-interaction"></div>
            <div class="col-12 col-sm-9 offset-sm-3 col-md-7 offset-md-0 col-xl-5">
                <div class="row key-number-row" >
                    <div class="col-6 col-md-4 col-xl-6" v-for="(item, index) in data.rows" :key="index" >
                        <Keynumber 
                            :value="item.value" 
                            :description="item.description" 
                            :unit="item.unit" 
                            :currency="item.currency">
                        </Keynumber>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 col-md-5 col-xl-4"></div>
            <div class="col-6 col-md-3 d-block d-sm-none d-md-block d-xl-none">
                <terms-text>
                    <slot name="terms-text">
                        <!-- Terms text on mobile -->
                    </slot>
                </terms-text>
                <terms :footnotes="footnotes"></terms>
            </div>
        </div>
        <!-- <div class="row u-disabled-interaction" style="height: 100px;"></div> -->
        <section-bottom-fill></section-bottom-fill>
    </div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import PinedSection from '@/components/pined-section-container'
import BackgroundContainer from '@/components/section-background-container'
import Keynumber from '@/views/key-number'
import TermsText from '@/views/terms-text'
import Terms from '@/views/terms'
import sectionBottomFill from '@/views/section-bottom-fill'

export default {
  name: 'key-numbers',
  mixins: [storeWatchMixin],
  components:{
    PinedSection,
    Keynumber,
    TermsText,
    Terms,
    BackgroundContainer,
    sectionBottomFill
  },
  props:{
    data:{
        type: Object,
        required: true,
        default: () => {rows: []}
    },
    footnotes:{
        type: Array,
        default: () => {[]}
    }
  }
}
</script>

<style lang="scss">

</style>