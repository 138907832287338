<template>
<div class="container-fluid u-disabled-interaction" style="position: fixed; top: 0; left: 0; z-index: 970; height: 100vh;">
    
  <div class="navi-pictet-bar-tablet d-md-none"></div>

  <div class="container container-navi">
    
    <!-- Mobile dark bg -->
    <div class="annual-navigation-mobile-fill u-enabled-interaction utils-mouse-disabled" @click="closeNavigation"></div>
        
    <nav id="annual-navigation" class="annual-navi ">
        <div class="annual-navi-bg"></div>
        <ul class="page-nav u-enabled-interaction">
            <li id="intro-page-nav" class="item-nav item-active" @click="gotoSection(1, 'home', 0, 'introduction')">Introduction</li>
            <li id="pictet-page-nav" class="item-nav">
                <div @click="gotoSection(2, 'page0', 0, 'pictet-group')"> The Pictet Group</div>
                <ul class="page-subnav">
                  <div class="navi-line"></div>
                  <div id="navi-line1" class="navi-line-full"></div>
                  <li @click="gotoSection(0, 'page1', 800)" id="pictet-about-page-nav" class="item-subnav">About Us</li>
                  <li @click="gotoSection(0, 'page2')" id="pictet-financial-page-nav" class="item-subnav">Financial Summary</li>
                  <li @click="gotoSection(0, 'page3')" id="pictet-history-page-nav" class="item-subnav">Our History</li>
                </ul>
            </li>
            <li @click="gotoSection(3, 'page4', 0, 'ambition')" id="ambition-page-nav" class="item-nav">Our Ambition 2025</li>
            <li id="business-page-nav" class="item-nav">
                <div @click="gotoSection(4,'business-page-start', 0, 'business')">Our Business</div>
                <ul class="page-subnav">
                  <div class="navi-line"></div>
                  <div id="navi-line2" class="navi-line-full"></div>
                  <li @click="gotoSection(0, 'business-page-id-1', 800)" id="business-page-1" class="item-subnav">Wealth Management</li>
                  <li @click="gotoSection(0, 'business-page-id-2')" id="business-page-2" class="item-subnav">Asset Management</li>
                  <li @click="gotoSection(0, 'business-page-id-3')" id="business-page-3" class="item-subnav">Alternative Advisors</li>
                  <li @click="gotoSection(0, 'business-page-id-4')" id="business-page-4" class="item-subnav">Asset Services</li>
                </ul>
            </li>
            <li @click="gotoSection(5, 'acting-responsibly-page-start', 0, 'acting-sustainably')" id="responsible-page-nav" class="item-nav">Acting Sustainably</li>
            <!-- @click="gotoSection(6, 'get-review-page-start', 0, 'get-the-annual-reviews')"  -->
            <li @click="goToAnnualReview" id="get-review-page-nav" class="item-nav">Get the Annual Review</li>
        </ul>
    </nav>

    <div class="navi-hamburger-ico u-enabled-interaction" @click="toggleMenu">
      <div id="line1" class="line"></div>
      <div id="line2" class="line"></div>
      <div id="line3" class="line"></div>
    </div>
    
</div>
</div>
</template>

<script>
import storeWatchMixin from '@/mixins/storeWatchMixin'
import gsap from 'gsap'
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollScene } from 'scrollscene'

export default {
  name: 'navigation',
  mixins: [storeWatchMixin],
  data (){
    return{
      currentSection: 0,
      isMenuOpened: false,
      scrollMagicController: null,
      toggleMenuTimeline: null
    }
  },
  mounted(){
    gsap.registerPlugin(ScrollToPlugin); 
    this.$nextTick(function () {
      this.initScene()
    });
    this.onResize ()
    window.eventBus.$on('SCROLL_TO_HOME', () => {
      this.gotoSection(1, 'home', 0, 'introduction');
    })
    window.eventBus.$on('SCROLL_TO_GET_ANNUAL_REVIEW', () => {
      this.gotoSection(6, 'get-the-annual-review-content', 800, 'get-the-annual-reviews');
    })
    
  },
  methods: {
    onResize (wWidth, wHeight){
      $(".annual-navi-bg").innerHeight($(window).height() + "px");

      $(".annual-navi-bg").html($(window).height());
    },
    closeNavigation(){
      if (this.isMenuOpened)
        this.toggleMenu ();
    },
    gotoSection(lp, id, offset = 0, route) {
      
      this.$store.dispatch('setUpdateURLonScroll', false);
      
      if (offset == 800)
        offset = this.windowHeight

      if(route)
        this.$router.push({ name: route})

      if (this.isMenuOpened)
        this.toggleMenu();
      
      window.blockHashUpdate = true;
      

      var currrentScrollTop = $(window).scrollTop();   
      var scrollDiff = ($('#'+id).offset().top + offset) - currrentScrollTop
      var duration = Math.abs(scrollDiff / 5000)
      
      if(lp != 0){
        window.eventBus.$emit('on-show-transition', lp);
      gsap.timeline({delay: .35, onComplete: () => {
        
        this.scrollMagicController.update();
        setTimeout(() => this.$store.dispatch('setUpdateURLonScroll', true), 500)
      }})
      
      .set (window, {scrollTo: $('#'+id).offset().top + offset, duration : duration, ease: 'power1.inout'});
      }else{
         gsap.timeline({onComplete: () => {
         
        this.scrollMagicController.update();
        setTimeout(() => this.$store.dispatch('setUpdateURLonScroll', true), 500)
        
      }})
      
      .to (window, {scrollTo: $('#'+id).offset().top + offset, duration : duration, ease: 'power1.inout'});
      }
      


      setTimeout(() => {
            window.blockHashUpdate = false;
            // this.scrollMagicController.update();
          }, 200)
    },
    goToAnnualReview (){
      
      window.eventBus.$emit('SCROLL_TO_GET_ANNUAL_REVIEW');
      
    },
    toggleMenu(){

      if (this.toggleMenuTimeline)
        this.toggleMenuTimeline.kill()

      if(this.isMenuOpened == false){
        let gsapObj = {ease: "power2.out", duration: 0.35}
        let gsapObj2 = {ease: "power2.out", duration: 0.35}

        $(".annual-navigation-mobile-fill").removeClass("utils-mouse-disabled");
        $(".navi-hamburger-ico").addClass("utils-mouse-disabled");
        
        this.toggleMenuTimeline = gsap.timeline()
          .to(".annual-navigation-mobile-fill",  {autoAlpha: 1, ...gsapObj }, 'sync')
          .to("#annual-navigation",  {x: "0%", ...gsapObj }, 'sync')
          .to("#annual-navigation ul.page-nav",  {x: "0%", ...gsapObj }, 'sync')

          .to("#line2",  {autoAlpha: 0, scale: 0, ease: gsapObj2.ease, duration : gsapObj2.duration/2 }, 'sync')
          .to("#line1",  {rotation: 180 + 45, y: "4", ...gsapObj2, transformOrigin: "50% 50%" }, 'sync')
          .to("#line3",  {rotation: -180 - 45, y: "-4", ...gsapObj2, transformOrigin: "50% 50%" }, 'sync')
      }else{
        let gsapObj = {ease: "power2.out", duration: 0.35}
        let gsapObj2 = {ease: "power2.out", duration: 0.35}
        
        $(".annual-navigation-mobile-fill").addClass("utils-mouse-disabled");
        $(".navi-hamburger-ico").removeClass("utils-mouse-disabled");

        this.toggleMenuTimeline = gsap.timeline()
          .to(".annual-navigation-mobile-fill",  {autoAlpha: 0, ...gsapObj }, 'sync')
          .to("#annual-navigation",  {x: "100%", ...gsapObj }, 'sync')
          .to("#annual-navigation ul.page-nav",  {x: "-75%", ...gsapObj }, 'sync')


          .to("#line2",  {autoAlpha: 1, scale: 1, ...gsapObj2 }, 'sync')
          .to("#line1",  {rotation: 0, y: 0, ...gsapObj2, transformOrigin: "50% 50%" }, 'sync')
          .to("#line3",  {rotation: 0, y: 0, ...gsapObj2, transformOrigin: "50% 50%" }, 'sync')
      }
      this.isMenuOpened = !this.isMenuOpened;
    },
    // onResize (wWidth, wHeight){
    //   wWidth, wHeight      
    // },
    initScene(){

      // INTRODUCTION
      var scene = new ScrollScene({
                    triggerElement: "#page0",
                    triggerHook: 0.75,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                                  .to("#intro-page-nav",  {duration: 0.1, opacity:0.4, ease: 'power3.inout'}) 
                                  .to("#pictet-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout'}, 0)
                                  .to("#pictet-page-nav",  {duration: 0.4, height: 30 + $("#pictet-page-nav .page-subnav").height(), ease:'power3.inout'}, 0)
                                  .to("#navi-line1",  {duration: 0, scaleY: 0, transformOrigin:"0px 0px", ease:'power3.inout'}, 0)
                    }
                })
      this.scrollMagicController = scene.Scene.controller ();
      
      // PICTET GROUP 1
      new ScrollScene({
                      triggerElement: "#page1",
                      duration: 500,
                      triggerHook: 0,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                            .to("#pictet-about-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout' , overwrite: 'auto'}, 0)
                            .to("#navi-line1", {duration: 0.3, scaleY: 0.33, transformOrigin:"0px 0px", ease:'power3.inout' , overwrite: 'auto'}, 0)
                      }
                  })
      

      // PICTET GROUP 2
      new ScrollScene({
                      triggerElement: "#page2",
                      duration: 500,
                      triggerHook: 1,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                                    .to("#pictet-about-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                                    .to("#pictet-financial-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                                    .to("#navi-line1",  {duration: 0.3, scaleY: 0.66, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)
                      }
                  })

      // PICTET GROUP 3
      new ScrollScene({
                      triggerElement: "#page3",
                      duration: 500,
                      triggerHook: 1,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                                    .to("#pictet-about-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                                    .to("#pictet-financial-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                                    .to("#pictet-history-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                                    .to("#navi-line1",  {duration: 0.3, scaleY: 1, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)
                      }
                  })

      // AMBITION 1
      new ScrollScene({
                    triggerElement: "#page4",
                    triggerHook: 1,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                      .to("#pictet-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout'}, 0)
                      // .to("#pictet-page-nav",  {duration: 0.4, height: 30, ease:'power3.inout'}, 0)
                      .to("#navi-line1",  {duration: 0.1, scaleY: 1, transformOrigin:"0px 0px", ease:'power3.inout'}, 0)
                      .to("#ambition-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout'}, 0)
                    }
                })



      // BUSINESS Start
      new ScrollScene({
                    triggerElement: "#business-page-start",
                    triggerHook: 1,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                        .to("#ambition-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                        .to("#business-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                    }
                })

      // BUSINESS Start
      new ScrollScene({
                    triggerElement: "#business-page-start",
                    triggerHook: 1,
                    offset: 200,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                        .to("#business-page-nav",  {duration: 0.3, height: 30 + $("#business-page-nav .page-subnav").height(), overwrite: 'auto', ease:'power3.inout'}, 0)
                        .to("#navi-line2",  {duration: 0, scaleY: 0, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)
                    }
                })

      // BUSINESS 1
      new ScrollScene({
                      triggerElement: "#business-page-id-1",
                      duration: 500,
                      triggerHook: 0,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                          .to("#business-page-1",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#navi-line2",  {duration: 0.3, scaleY: 0.25, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)

                      }
                  })

      // BUSINESS 2
      new ScrollScene({
                      triggerElement: "#business-page-id-2",
                      duration: 500,
                      triggerHook: 1,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                          .to("#business-page-1",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#business-page-2",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#navi-line2",  {duration: 0.3, scaleY: 0.5, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)

                      }
                  })

      // BUSINESS 3
      new ScrollScene({
                      triggerElement: "#business-page-id-3",
                      duration: 500,
                      triggerHook: 1,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                          .to("#business-page-2",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#business-page-3",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#navi-line2",  {duration: 0.3, scaleY: 0.75, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)

                      }
                  })
      
      // BUSINESS 4
      new ScrollScene({
                      triggerElement: "#business-page-id-4",
                      duration: 500,
                      triggerHook: 1,
                      gsap: {
                        timeline: gsap.timeline({paused: true})
                          .to("#business-page-3",  {duration: 0.3, opacity: 0.4, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#business-page-4",  {duration: 0.3, opacity: 1, ease:'power3.inout', overwrite: 'auto'}, 0)
                          .to("#navi-line2",  {duration: 0.3, scaleY: 1, transformOrigin:"0px 0px", ease:'power3.inout', overwrite: 'auto'}, 0)

                      }
                  })



      // RESPONSIBLE 1
      new ScrollScene({
                    triggerElement: "#acting-responsibly-page-start",
                    triggerHook: 1,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                      .to("#business-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout'}, 0)
                      .to("#navi-line2",  {duration: 0.3, scaleY: 1, transformOrigin:"0px 0px", ease:'power3.inout'}, 0)
                      .to("#responsible-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout'}, 0)
                    }
                })

      // GET REVIEW 1
      new ScrollScene({
                    triggerElement: "#get-review-page-start",
                    triggerHook: 1,
                    duration: 500,
                    gsap: {
                      timeline: gsap.timeline({paused: true})
                      .to("#responsible-page-nav",  {duration: 0.3, opacity: 0.4, ease:'power3.inout'}, 0)
                      .to("#get-review-page-nav",  {duration: 0.3, opacity: 1, ease:'power3.inout'}, 0)
                    }
                })
                

    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
